// alertas
import Toast from "../Alert";
import { Link } from "react-router-dom";
import { useState, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import React from "react";
import moment from "moment";
import {
	IconEye,
	IconId,
	IconReceipt2,
	IconFileDescription,
	IconSend,
	IconHandOff,
} from "@tabler/icons-react";
import BeneficiaryModal2 from "../modals/BeneficiaryModal2";
import ViewModal from "../modals/ViewModal";
import UploadModal from "../modals/UploadModal";
import { useSelector } from "react-redux";
import { IconPencil, IconChevronDown } from "@tabler/icons-react";
import badgeStandard from "../../assets/img/latir-badge-standard.png";
import badgePlus from "../../assets/img/latir-badge-plus.png";
import badgePremium from "../../assets/img/latir-badge-premium.png";
import badgePlatinum from "../../assets/img/latir-badge-platinum.png";
import MembershipRenewModal from "../modals/MembershipRenewModal";
import DeleteClient from "../modals/DeleteClient";

function ClientsCardHorizontal({ data, index, refreshData }) {
	const user = useSelector((state) => state.auth.user);
	const [daysAffiliated, setDaysAffiliated] = useState(0);

	useEffect(() => {
		if (data.memberships && data.memberships.length > 0) {
			// Usando la fecha de afiliación de la primera membresía
			const membershipDate = moment(data.memberships[0].membership_day);
			const currentDate = moment();
			const totalDays = currentDate.diff(membershipDate, "days");
			setDaysAffiliated(totalDays);
		}
	}, [data.memberships]);

	const [formData, setFormData] = useState({
		username: "",
		password: "",
	});
	const today = moment().format("YYYY-MM-DD");

	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [openDeleteBeneficiary, setOpenDeleteBeneficiary] = useState(false);
	const [openImg, setOpenImg] = useState(false);
	const [openImgNuip, setOpenImgNuip] = useState(false);
	const [openImgServices, setOpenImgServices] = useState(false);

	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	const handleButtonClick = (e, nuip) => {
		e.preventDefault();

		const config = {
			headers: {
				Accept: "application/json",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};

		const formData = new FormData();
		formData.append("nuip", nuip);

		const fetchData = async () => {
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/payment/create/`,
					formData,
					config
				);
				console.log(res);
				if (res.status === 200) {
					Toast({
						tipo: "success",
						mensaje: "Se solicito renovacion correctamente!",
					});
				} else {
					Toast({
						tipo: "error",
						mensaje: "Error al renovar los datos del cliente",
					});
				}
			} catch (err) {
				if (
					err.response &&
					err.response.status === 400 &&
					err.response.data.error.includes("Invalid credentials.")
				) {
					Toast({ tipo: "error", mensaje: "Credenciales incorrectas" });
				}
				if (
					err.response &&
					err.response.status === 403 &&
					err.response.data.error.includes("User is not active.")
				) {
					Toast({
						tipo: "error",
						mensaje:
							"No posees los suficientes permisos para realizar esta acción",
					});
				}
			}
		};
		fetchData();
		setIsButtonDisabled(true); // Aquí puedes agregar la lógica para enviar el correo

		setTimeout(() => {
			setIsButtonDisabled(false);
		}, 5000);
	};

	const handleButtonClickResend = (e, nuip) => {
		e.preventDefault();

		const config = {
			headers: {
				Accept: "application/json",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};
		const formData = new FormData();
		formData.append("nuip", nuip);

		const fetchData = async () => {
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/token/verfied/`,
					formData,
					config
				);
				if (res.status === 200) {
					Toast({
						tipo: "success",
						mensaje: "Se reenvió el correo correctamente!",
					});
				} else {
					Toast({
						tipo: "error",
						mensaje: "Error al reenviar el correo",
					});
				}
			} catch (err) {
				if (
					err.response &&
					err.response.status === 400 &&
					err.response.data.error.includes("Invalid credentials.")
				) {
					Toast({ tipo: "error", mensaje: "Credenciales incorrectas" });
				}
				if (
					err.response &&
					err.response.status === 403 &&
					err.response.data.error.includes("User is not active.")
				) {
					Toast({
						tipo: "error",
						mensaje:
							"No posees los suficientes permisos para realizar esta acción",
					});
				}
			}
		};
		fetchData();
	};

	const onSubmitDeleteClient = (e, id, email, password) => {
		e.preventDefault();

		setLoading(true);

		const config = {
			headers: {
				Accept: "application/json",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
			data: {
				// Incluye los datos en la configuración
				email: email,
				password: password,
			},
		};

		axios
			.delete(`${process.env.REACT_APP_API_URL}/client/delete/${id}/`, config)
			.then((res) => {
				if (res.status === 204) {
					Toast({
						tipo: "success",
						mensaje: "Cliente Eliminado correctamente!",
					});
					navigate("/clients");
					window.location.reload();
				} else {
					Toast({
						tipo: "error",
						mensaje: "Error al Eliminar el cliente",
					});
				}
			})
			.catch((err) => {
				Toast({ tipo: "error", mensaje: "Error en el servicio" });
			})
			.finally(() => {
				setOpen(false);
				setLoading(false);
			});
	};

	const onSubmitDeleteBeneficiary = (e, id, email, password) => {
		e.preventDefault();

		setLoading(true);

		const config = {
			headers: {
				Accept: "application/json",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
			data: {
				// Incluye los datos en la configuración
				email: email,
				password: password,
			},
		};
		axios
			.delete(
				`${process.env.REACT_APP_API_URL}/beneficiary/delete/${id}/`,
				config
			)
			.then((res) => {
				if (res.status === 204) {
					Toast({
						tipo: "success",
						mensaje: "Beneficiario Eliminado correctamente!",
					});
					refreshData();
				} else {
					Toast({
						tipo: "error",
						mensaje: "Error al Eliminar el Beneficiario",
					});
				}
			})
			.catch((err) => {
				if (
					err.response.status === 401 &&
					err.response.data.error === "Invalid credentials."
				) {
					Toast({ tipo: "error", mensaje: "Credenciales incorrectas" });
				} else {
					Toast({ tipo: "error", mensaje: err.response.data.error });
				}
			})
			.finally(() => {
				setOpen(false);
				setLoading(false);
			});
	};

	const toggleBeneficiaries = (e, id) => {
		e.preventDefault();
		const beneficiaries = document.querySelectorAll(
			`tr[data-parent="${data.id}"]`
		);
		beneficiaries.forEach((beneficiary) => {
			beneficiary.classList.toggle("hidden");
		});
	};

	return (
		<React.Fragment key={index}>
			<tr className="bg-white border-b">
				<td className="p-2">
					<div className="flex text-xs">
						{data.memberships &&
							data.memberships.map((membership, index) => (
								<div key={index} className="flex flex-col justify-center">
									<div
										className={
											`rounded-full w-5 h-5 ` +
											(moment().isAfter(moment(membership.expiry_date))
												? "bg-red-500" // Rojo si está vencido
												: moment(membership.expiry_date).diff(
														moment(),
														"months"
												  ) <= 1
												? "bg-warning" // Amarillo si falta 1 mes o menos para vencerse
												: "bg-success") // Verde si está activo y no está cerca de vencerse
										}
									>
										{/* contenido del div */}
									</div>
								</div>
							))}
					</div>
				</td>
				<td className="p-2">
					<div className="flex text-xs">
						<span className="font-bold">Doc.</span>
						<span className="flex ml-1">
							{data.document_type}-{data.nuip}
						</span>
					</div>
					<div className="flex text-xs">
						<span className="font-bold">Nombre: </span>{" "}
						<span className="flex ml-1">
							{data.first_name} {data.last_name}
						</span>
					</div>
					<div className="flex text-xs">
						<span className="font-bold">Género: </span>{" "}
						<span className="flex ml-1">
							{data.gender === "M"
								? "Masculino"
								: data.gender === "F"
								? "Femenino"
								: "Otro"}
						</span>
					</div>
				</td>
				<td className="p-2">
					<div className="flex text-xs">
						<span className="font-bold">Teléfono: </span>{" "}
						<span className="flex ml-1">{data.phone}</span>
					</div>
					<div className="flex text-xs">
						<span className="font-bold">Correo: </span>{" "}
						<span className="flex ml-1">{data.email}</span>
					</div>
				</td>
				<td className="p-2 text-xs">{data.pathologies}</td>
				<td className="p-2 text-xs">{data.stratum}</td>
				<td className="p-2 text-xs">
					<div className="flex items-center gap-1">
						{data && data.plans
							? (() => {
									switch (data.plans) {
										case "CLASSIC":
											return (
												<>
													<img
														src={badgeStandard}
														className="w-8"
														alt="badge standard"
													/>
													<span>Standard</span>
												</>
											);
										case "PLUS":
											return (
												<>
													<img
														src={badgePlus}
														className="w-8"
														alt="badge plus"
													/>
													<span>Plus</span>
												</>
											);
										case "PREMIUM":
											return (
												<>
													<img
														src={badgePremium}
														className="w-8"
														alt="badge premium"
													/>
													<span>Premium</span>
												</>
											);
										case "PLATINUM":
											return (
												<>
													<img
														src={badgePlatinum}
														className="w-8"
														alt="badge platinum"
													/>
													<span>Platinum</span>
												</>
											);
										default:
											return "Sin Especificar";
									}
							  })()
							: "Sin Especificar"}
					</div>
				</td>

				<td className="p-2 text-xs">
					<div>
						<span className="font-bold">Mesa:</span>{" "}
						{data.table && data.table ? data.table : "-"}
					</div>
					<div>
						<span className="font-bold">Nivel:</span>{" "}
						{data.level && data.level ? data.level + 1 : 0 + 1}
					</div>
				</td>
				<td className="w-56 p-2 text-xs">
					{data.memberships &&
						data.memberships.map((membership, index) => (
							<div key={index} className="flex flex-col justify-center">
								<div>
									<span className="font-bold text-[#00496B]">
										F. Afiliación:
									</span>{" "}
									{membership.membership_day
										? moment(membership.membership_day).format("DD/MM/YYYY")
										: "-"}
								</div>
								<div>
									<span className="font-bold text-[#00496B]">
										F. Expiración:
									</span>{" "}
									{membership.expiry_date
										? moment(membership.expiry_date).format("DD/MM/YYYY")
										: "-"}
								</div>
								{/* Aquí puedes agregar más información de cada membresía si es necesario */}
							</div>
						))}
					<div>
						<span className="font-bold">
							{daysAffiliated === 0 ? "Día" : "Días"}:
						</span>{" "}
						{daysAffiliated ? daysAffiliated : 0}{" "}
					</div>
				</td>
				<td className="p-2 text-xs space-y-1.5 w-32">
					<div className="">
						{data.status ? (
							<div className="text-xs badge badge-success">Activo</div>
						) : (
							<div className="text-xs badge badge-warning">Inactivo</div>
						)}
					</div>
					<div className="">
						{data.token_verified ? (
							<div className="text-xs badge badge-success">Verificado</div>
						) : (
							<div className="text-xs badge badge-warning">No verificado</div>
						)}
					</div>
				</td>
				<td>
					<div className="flex gap-2">
						{data.img_nuip && data.img_nuip ? (
							<>
								<ViewModal
									setOpenImg={setOpenImgNuip}
									data={data.img_nuip}
									title={"Ver Documento"}
									icon={<IconId />}
								/>
							</>
						) : null}
						{data.img_services && data.img_services ? (
							<>
								<ViewModal
									setOpenImg={setOpenImgServices}
									data={data.img_services}
									title={"Ver Recibo"}
									icon={<IconReceipt2 />}
								/>
							</>
						) : null}
					</div>
				</td>
				<td className="p-2 text-xs">
					<div className="flex gap-2">
						<div className="tooltip flex" data-tip="Generar Reporte">
							<Link
								className="p-1 bg-teal-400 text-white rounded-full"
								to={`/client-report/${data.id}`}
								target="_blank"
							>
								<IconFileDescription />
							</Link>
						</div>

						{data.token_verified ? (
							// Validar si `enterprise` es null o cumple con la condición específica
							data.enterprise &&
							(data.enterprise.name_enterprise === "SIN EMPRESA" ||
								data.enterprise.corpoindustrial === false) || data.enterprise===null ? (
								// Mostrar el modal si la empresa cumple con las condiciones
								<div>
									{data.payment_image && data.payment_image.img_payment ? (
										<ViewModal
											setOpenImg={setOpenImg}
											data={data.payment_image.img_payment}
											title={"Ver Pago"}
											icon={<IconEye />}
										/>
									) : (
										data.memberships &&
										data.memberships.length === 0 && (
											<UploadModal
												setOpenImg={setOpenImg}
												data={data}
												openImg={openImg}
											/>
										)
									)}
								</div>
							) : (
								// Si no se cumple la condición de la empresa, mostrar el tooltip
								<div
									className="tooltip flex"
									data-tip="Credito por libranza no amerita cargar pago"
								>
									<Link className="p-1 bg-green-500 text-white rounded-full cursor-pointer">
										<IconHandOff />
									</Link>
								</div>
							)
						) : (
							// En caso de que no se haya verificado el token, mostrar opción de reenviar activación
							<div className="flex tooltip" data-tip="Reenviar activación">
								<Link
									onClick={(e) => handleButtonClickResend(e, data.nuip)}
									className={`p-1 text-white bg-green-500 rounded-full ${
										isButtonDisabled ? "disabled" : ""
									}`}
									disabled={isButtonDisabled}
								>
									<IconSend />
								</Link>
							</div>
						)}

						{moment().diff(moment(data.membership_day), "months") === 1 ||
						(data.beneficiaries && data.beneficiaries.length >= 3) ? (
							<></>
						) : data.token_verified && data.token_verified ? (
							<BeneficiaryModal2 client={data.id} refreshData={refreshData} />
						) : (
							""
						)}
						{(user && (user.id === data.user || user.role === "ADMIN")) ||
						user.role === "SIAU-REPRESENTANTES" ? (
							<div className="flex tooltip" data-tip="Editar">
								<Link
									to={`/edit-clients/${data.id}`}
									className="p-1 text-white bg-yellow-500 rounded-full"
								>
									<IconPencil />
								</Link>
							</div>
						) : (
							""
						)}
						{/* {user && user.role === "ADMIN" ? (
							<DeleteClient
								data={data}
								onSubmitDelete={(e) => onSubmitDelete(e)}
								openDelete={openDelete}
								setOpenDelete={setOpenDelete}
							/>
						) : (
							<>
								<div className="tooltip flex" data-tip="Eliminar">
									<a
										onClick={(e) => setOpenDelete(true)}
										className="p-1 bg-red-500 text-white rounded-full cursor-pointer"
									>
										<IconTrash />
									</a>
								</div>

								<Transition.Root show={openDelete} as={Fragment}>
									<Dialog
										as="div"
										className="relative z-10"
										onClose={setOpenDelete}
									>
										<Transition.Child
											as={Fragment}
											enter="ease-out duration-300"
											enterFrom="opacity-0"
											enterTo="opacity-100"
											leave="ease-in duration-200"
											leaveFrom="opacity-100"
											leaveTo="opacity-0"
										>
											<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
										</Transition.Child>

										<div className="fixed inset-0 z-10 overflow-y-auto">
											<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
												<Transition.Child
													as={Fragment}
													enter="ease-out duration-300"
													enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
													enterTo="opacity-100 translate-y-0 sm:scale-100"
													leave="ease-in duration-200"
													leaveFrom="opacity-100 translate-y-0 sm:scale-100"
													leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
												>
													<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-xl sm:p-6">
														<div>
															<div className="mt-3 text-center">
																<Dialog.Title
																	as="h3"
																	className="text-lg font-medium leading-6 text-gray-900"
																>
																	<span>¿Estás seguro?</span>
																</Dialog.Title>
																<div className="mt-2">
																	<p className="text-sm text-gray-500">
																		Deseas eliminar el cliente y a todos sus
																		beneficiarios directos?
																	</p>
																</div>
															</div>
														</div>
														<div className="flex justify-center">
															<form
																onSubmit={(e) => onSubmitDelete(e, data.id)}
																className="w-8/12"
															>
																<h2 className="my-4">
																	Ingrese las credenciales del superior
																	encargado
																</h2>
																<div className="form-control mb-4">
																	<input
																		type="text"
																		name="username"
																		value={username}
																		onChange={(e) => onChange(e)}
																		placeholder="Nombre de usuario"
																		className="input input-bordered w-full"
																	/>
																</div>
																<div className="form-control">
																	<input
																		type="password"
																		name="password"
																		value={password}
																		onChange={(e) => onChange(e)}
																		placeholder="Contraseña"
																		className="input input-bordered w-full"
																	/>
																</div>
																<div className="mt-5 flex gap-4 justify-center">
																	<button
																		type="button"
																		onClick={(e) => setOpenDelete(false)}
																		className="btn border bg-[#0096C4] text-white"
																	>
																		<span>Cancelar</span>
																	</button>

																	<button
																		type="submit"
																		className="btn border bg-[#c32a2a] text-white"
																	>
																		<span>Confirmar</span>
																	</button>
																</div>
															</form>
														</div>
													</Dialog.Panel>
												</Transition.Child>
											</div>
										</div>
									</Dialog>
								</Transition.Root>
							</>
						)} */}
						{data.memberships &&
							data.memberships.map((membership, index) => {
								const isMembershipExpired =
									membership.expiry_date && membership.expiry_date <= today;
								const isMembershipValidated = membership.validated_payment;

								if (isMembershipExpired && isMembershipValidated) {
									// Si la membresía ha expirado y está validada, muestra el botón de renovación
									return (
										// <div
										// 	key={index}
										// 	className="flex tooltip"
										// 	data-tip="Renovar membresia"
										// >
										// 	<Link
										// 		onClick={(e) => handleButtonClick(e, data.nuip)}
										// 		className={`p-1 text-white bg-green-500 rounded-full ${
										// 			isButtonDisabled ? "disabled" : ""
										// 		}`}
										// 		disabled={isButtonDisabled}
										// 	>
										// 		<IconSend />
										// 	</Link>
										// </div>

										<MembershipRenewModal
											key={index}
											data={data}
											handleButtonClick={handleButtonClick}
											isButtonDisabled={isButtonDisabled}
											onSubmitDeleteClient={onSubmitDeleteClient}
										/>
									);
								} else {
									// En otro caso, no se muestra el botón
									return null;
								}
							})}
					</div>
				</td>

				<td>
					<div className="flex items-center">
						{data.beneficiaries && data.beneficiaries.length > 0 ? (
							<span
								className="p-1 bg-gray-200 rounded-full cursor-pointer tooltip"
								data-tip="Más"
								onClick={(e) => toggleBeneficiaries(e)}
							>
								<IconChevronDown />
							</span>
						) : (
							""
						)}
					</div>
				</td>
			</tr>
			{data.beneficiaries &&
				data.beneficiaries.map((beneficiary, beneficiaryIndex) => (
					<tr
						key={`beneficiary-${beneficiaryIndex}`}
						className="hidden bg-gray-200 border-b"
						data-parent={data.id}
					>
						<td className="p-2"></td>
						<td className="p-2 text-xs">
							<div className="flex">
								<span className="font-bold">Doc.</span>{" "}
								<span className="flex ml-1">
									{beneficiary.document_type}-{beneficiary.nuip}
								</span>
							</div>
							<div className="flex">
								<span className="font-bold">Nombre:</span>{" "}
								<span className="flex ml-1">
									{beneficiary.first_name} {beneficiary.last_name}
								</span>
							</div>
							<div className="flex">
								<span className="font-bold">Género:</span>{" "}
								<span className="flex ml-1">
									{beneficiary.gender === "M" ? "MASCULINO" : "FEMENINO"}
								</span>
							</div>
						</td>
						<td className="p-2 text-xs">
							<div className="flex">
								<span className="font-bold">Teléfono: </span>{" "}
								<span className="flex ml-1">{beneficiary.phone}</span>
							</div>
							<div className="flex">
								<span className="font-bold">Correo: </span>{" "}
								<span className="flex ml-1">{beneficiary.email}</span>
							</div>
						</td>
						<td className="p-2 text-xs">{beneficiary.pathologies}</td>
						<td className="p-2 text-xs">{beneficiary.stratum}</td>
						<td className="p-2 text-xs">{"-"}</td>
						<td className="p-2 text-xs">{"-"}</td>
						<td className="p-2 text-xs">{"-"}</td>
						<td className="p-2 text-xs">{"-"}</td>
						<td className="p-2 text-xs">{"-"}</td>
						<td className="p-2 text-xs">
							<div className="flex gap-2">
								{(user && (user.id === data.user || user.role === "ADMIN")) ||
								(user && user.role === "SIAU-REPRESENTANTES") ? (
									<div className="flex tooltip" data-tip="Editar">
										<Link
											to={`/edit-beneficiaries/${beneficiary.id}`}
											className="p-1 text-white bg-yellow-500 rounded-full"
										>
											<IconPencil />
										</Link>
									</div>
								) : (
									""
								)}

								<DeleteClient
									data={beneficiary}
									onSubmitDeleteClient={onSubmitDeleteBeneficiary}
									type={"beneficiario"}
								/>
							</div>
						</td>
						<td></td>
					</tr>
				))}
		</React.Fragment>
	);
}
export default ClientsCardHorizontal;
