import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { Fragment, useState } from "react";
import CircleLoader from "react-spinners/CircleLoader";
import Toast from "../Alert";

import { 
  IconCheckbox,
} from '@tabler/icons-react';
import { Link } from "react-router-dom";

function VerifyModal ({ data }) {
	const [openVerify, setOpenVerify] = useState(false);
	const [acceptedTerms, setAcceptedTerms] = useState(false);
	const [loading, setLoading] = useState(false);

	
	const [formData, setFormData] = useState({
		token_key: "",
	});
	const { token_key } = formData;
	const handleModalClose = () => {
		setOpenVerify(false);
	};

	const handleAcceptTerms = (event) => {
		setAcceptedTerms(event.target.checked);
	};

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		


		if (token_key) {
			const config = {
				headers: {
					// Authorization: `JWT ${localStorage.getItem("access")}`,
					Accept: "application/json",
				},
			};

			const formData = new FormData();
			formData.append("token_key", token_key);
			formData.append("client_id", data);

			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/token/verfied/`,
					formData,
					config
				);

				if (res.status === 200) {
					setLoading(false);
					setFormData({
						token_key: "",
					});

					Toast({
						tipo: "success",
						mensaje: "¡Verificacion Exitosa!",
					});
					setOpenVerify(false);
					setLoading(false);
					window.location.reload();
				}
			} catch (error) {
				if (
					error.response &&
					error.response.status === 400 &&
					error.response.data.error.includes(
						"Client does not exist or token is invalid"
					)
				) {
					Toast({
						tipo: "error",
						mensaje: "El cliente no existe o el token es invalido",
					});
					setLoading(false);
				} else {
					Toast({ tipo: "error", mensaje: "Error al verificar el token"});
					setLoading(false);
					setOpenVerify(false);
				}

				setOpenVerify(false);
				setLoading(false);
			}
		} else {
			Toast({ tipo: "error", mensaje: "Por favor ingrese un token valido"});
			setLoading(false);
			setOpenVerify(false);
		}
	};

	return (
		<>
			<div className="tooltip flex" data-tip="Verificar Token">
				<Link
					onClick={() => setOpenVerify(true)}
					className="p-1 bg-green-500 text-white rounded-full cursor-pointer"
				>
					<IconCheckbox />
				</Link>
			</div>

			<Transition.Root show={openVerify} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={handleModalClose}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
									{acceptedTerms ? (
										// Renderizar los datos del otro modal aquí
										<>
										
										<div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        <span>Verificar código de cliente</span>
                      </Dialog.Title>
                    </div>
                  </div>
                  <form onSubmit={onSubmit} className="mt-5">
                    <div>
                      <input
                        type="text"
                        name="token_key"
                        value={token_key}
                        onChange={onChange}
                        required={false}
                        className="input input-bordered w-full"
                        placeholder="Codigo de verificación"
                      />
                    </div>
                    <div className="flex justify-center mt-4 space-x-3">
											<button
                        type="button"
                        className="btn border border-[#0096C4] bg-white text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B]"
                        onClick={() => setOpenVerify(false)}
                      >
                        Cerrar
                      </button>
                      {loading ? (
                        <div className="btn border bg-[#0096C4] text-white">
                          <CircleLoader loading={loading} size={25} color="#ffffff" />
                        </div>
                      ) : (
                        <button
                          type="button"
                          className="btn border bg-[#0096C4] hover:bg-[#00496B] text-white"
                          onClick={onSubmit}
                        >
                          Guardar
                        </button>
                      )}
                    </div>
                  </form>

										</>
									) : (
										// Renderizar los términos y condiciones aquí
										<>
										<div className="mt-6">
											<h3 className="text-lg font-medium text-gray-900">Términos y condiciones</h3>
											<p className="mt-2 text-sm text-gray-500">
												Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus, voluptates. Quo, voluptatum.
												Quisquam quibusdam, voluptatibus, quas voluptate, quod asperiores iusto quae quia voluptatum
												doloribus quidem? Voluptatibus, quas. Quisquam, quia.
											</p>
											<label htmlFor="acceptedTerms" className="flex items-center mt-4">
												<input
													type="checkbox"
													name="acceptedTerms"
													value={acceptedTerms}
													onChange={(event) => handleAcceptTerms(event)}
													required
													className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
												/>
												<span className="ml-2 text-sm text-gray-600">
													Acepto los términos y condiciones
												</span>
											</label>
										</div>
									</>
									)}
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
};

export default VerifyModal;