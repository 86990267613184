import PaymentsCardHorizontal from "./PaymentsCardHorizontal";


function PaymentsListSearch({ payments, get_shared_payments, count }) {
  return (
    <div className="overflow-hidden bg-white">
      <div className="relative overflow-x-auto">
        <table className="table text-gray-500">
				<thead className="text-xs text-gray-700 uppercase">
            <tr>
              <th scope="col" className="p-2">
                <div className="w-52">
                  Datos
                </div>
              </th>
              <th scope="col" className="p-2">
                <div className="w-48">
                  Factura
                </div>
              </th>
              <th scope="col" className="p-2">
                <div className="w-44">
								Fecha
								</div>
              </th>
              <th scope="col" className="p-2">
                Comprobante de Pago
              </th>
              <th scope="col" className="p-2">
                <div className="w-36">
                  Estatus
                </div>
              </th>
              <th scope="col" className="p-2">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {payments &&
              payments.map((token, index) => (
                <PaymentsCardHorizontal
                  data={token}
                  key={index}
                  index={index}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default PaymentsListSearch;
