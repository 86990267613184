import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import CircleLoader from "react-spinners/CircleLoader";
import axios from "axios";
import Toast from "../Alert";
import moment from "moment";
import { IconPencil } from "@tabler/icons-react";

function SearchModal({ discounts,onClose }) {
	const [loading, setLoading] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const [formData, setFormData] = useState({
		type: "",
		value: "",
		discount: "",
		invoice_code: "",
	});
	const {
		type,
		value,
		discount,
		invoice_code,
	} = formData;

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleClose = () => {
    onClose();
  }
	useEffect(() => {
		if (discounts) {
			setFormData({
				...formData,
				type: discounts.type,
				value: discounts.value,
				discount: discounts.discount,
				invoice_code: discounts.invoice_code,
			});
		}
	}, [discounts]);

	
	const onSubmit = async (e) => {
		setLoading(true);
			const config = {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `JWT ${localStorage.getItem("access")}`,
					Accept: "application/json",
				},
			};
		const formData = new FormData();
		formData.append("type", type);
		formData.append("value", value);
		formData.append("discount", discount);
		formData.append("invoice_code", invoice_code);
		formData.append("id", discounts.id);
		const fetchData = async () => {
			try {
				const res = await axios.put(
					`${process.env.REACT_APP_API_URL}/search/update/`,
					formData,
					config
				);
				if (res.status === 200) {
					setLoading(false);
					setFormData({
						type: "",
						value: "",
						discount: "",
						invoice_code: "",
					});
					Toast({
						tipo: "success",
						mensaje: "¡Descuento editado correctamente!",
					});
					handleClose();
					setModalIsOpen(false);
				}
			} catch (error) {
				setLoading(false);
				Toast({
					tipo: "error",
					mensaje: "¡Error! Por favor intente mas tarde",
				});
			}
			
		}
		fetchData();
	}

	return (
		<div>
			<div className="lg:tooltip" data-tip="Editar descuento">
				<button
					type="button"
					className="p-1 bg-yellow-500 text-white rounded-full"
					onClick={() => setModalIsOpen(true)}
				>
					<IconPencil />
				</button>
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => {
					setModalIsOpen(false);
					handleClose();
				}}
				ariaHideApp={false}
			>
				<div className="w-full h-100 shadow-card rounded-lg overflow-y-auto">
					<h1 className="font-bold text-xl text-gray-900">
						Edicion de Descuento
					</h1>
					<form onSubmit={onSubmit}>
						<div className="mt-5 mb-2 font-bold text-sm text-[#00496B]">
							Informacion Importante
						</div>

						<div className="border border-gray-300 rounded-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3">
							
						<div className="form-control">
								<label htmlFor="nuip" className="label">
									<span className="label-text">Tipo</span>
								</label>
								<input
									type="text"
									name="type"
									value={type}
									onChange={onChange}
									required

									className="input input-bordered w-full"
									placeholder="Tipo"
								/>
							</div>
							<div className="form-control">
								<label htmlFor="nuip" className="label">
									<span className="label-text">Valor</span>
								</label>
								<input
									type="text"
									name="value"
									value={value}
									onChange={onChange}
									required
									className="input input-bordered w-full"
									placeholder="Valor"
								/>
								</div>
							<div className="form-control">
								<label htmlFor="nuip" className="label">
									<span className="label-text">Descuento</span>
								</label>
								<input
									type="text"
									name="discount"
									value={discount}
									onChange={onChange}
									required
									className="input input-bordered w-full"
									placeholder="Descuento"
								/>
								</div>
							<div className="form-control">
								<label htmlFor="nuip" className="label">
									<span className="label-text">Codigo de Factura</span>
								</label>
								<input
									type="text"
									name="invoice_code"
									value={invoice_code}
									onChange={onChange}
									required
									className="input input-bordered w-full"
									placeholder="Codigo de Factura"
								/>
								</div>
								
								
						</div>



						<div className="flex justify-center mt-4 space-x-3">
							<button
								type="submit"
								className="btn border border-[#0096C4] bg-white text-[#0096C4] hover:bg-white hover:border-[#00496B] hover:text-[#00496B]"
								onClick={() => setModalIsOpen(false)}
							>
								Cerrar
							</button>
							{loading ? (
								<div className="btn border bg-[#0096C4] text-white">
									<CircleLoader loading={loading} size={25} color="#ffffff" />
								</div>
							) : (
								<button
									type="button"
									className="btn border bg-[#0096C4] hover:bg-[#00496B] text-white"
									onClick={onSubmit}
								>
									Guardar
								</button>
							)}
						</div>
					</form>
				</div>
			</Modal>
		</div>
	);
}

export default SearchModal;
