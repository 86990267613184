import React from "react";
import logoimg from "../../assets/img/logo.png";
import "../../assets/css/home.css";
import { Link } from "react-router-dom";
import { IconMenu2 } from "@tabler/icons-react";

function HomeNavbar() {
  return (
    <header className="fixed top-0 w-screen bg-white z-20 shadow-lg">
      <div className="container mx-auto">
        <nav className="flex justify-between items-center py-1 px-3">
          <div className="brand">
            <a href="/">
              <img src={logoimg} className="w-36" alt="Logo" />
            </a>
          </div>
          <div className="menu">
            <details className="dropdown dropdown-end bg-none flex lg:hidden">
              <summary className="m-1 btn bg-transparent border-none text-[#00496B]">
                <IconMenu2 />
              </summary>
              <ul className="p-2 shadow menu dropdown-content z-[1] bg-white text-[#00496B] rounded-box w-52">
                <li>
                  <a href="/https://culm.com.co" target="_blank">CULM</a>
                </li>
                <li>
                  <a href="https://farmaculm.com/" target="_blank">FARMACIA</a>
                </li>
                <li>
                  <Link to="/" target="_blank">VERIFICAR</Link>
                </li>
                <li>
                  <Link to="/login" target="_blank">INGRESAR</Link>
                </li>
                {/* <li>
                  <a href="/#verify">VERIFICAR</a>
                </li>
                <li>
                  <a href="/#plans">PLANES</a>
                </li>
                <li>
                  <a href="/#join-us">ÚNETE</a>
                </li>
                <li>
                  <a href="/login">INGRESAR</a>
                </li> */}
              </ul>
            </details>
            <ul className={"mx-4 hidden lg:flex items-center gap-3"}>
              <li>
                <a href="https://culm.com.co" target="_blank" className={"px-4 py-2 hover:bg-transparent"}>
                  CULM
                </a>
              </li>
              <li>
                <a href="https://farmaculm.com/" target="_blank" className={"px-4 py-2 hover:bg-transparent"}>
                  FARMACIA
                </a>
              </li>
              <li>
                <Link to="/" className={"px-4 py-2 hover:bg-transparent"}>
                  VERIFICAR
                </Link>
              </li>
              {/* <li>
                <a href="/#plans" className={"px-4 py-2 hover:bg-transparent"}>
                  PLANES
                </a>
              </li>
              <li>
                <a href="/#join-us" className={"px-4 py-2 hover:bg-transparent"}>
                  ÚNETE
                </a>
              </li> */}
              <li>
                <Link to="/login" className={"px-4 py-2 border border-[#0096C4] bg-white text-[#0096C4] hover:bg-[#0096C4] hover:text-white"}>
                  INGRESAR
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default HomeNavbar;
