import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import {
  IconHome,
  IconUser,
  IconUsers,
  IconMessageCircleCode,
  IconCash,
  IconReceiptTax,
  IconUsersGroup,
  IconReport,
  IconBriefcase,
  IconCurrencyDollar,
  IconChevronDown
} from '@tabler/icons-react';

function Sidebar() {
  const location = useLocation();
	const [isOpen, setIsOpen] = useState({});

  useEffect(() => {
    setIsOpen((prevIsOpen) => {
      const newIsOpen = { ...prevIsOpen };

      navigation.forEach((item) => {
        const hasSelectedChild = item.children?.some((child) => child.current);

        if (hasSelectedChild) {
          newIsOpen[item.name] = true;
        } else {
          newIsOpen[item.name] = false;
        }

        item.children?.forEach((child) => {
          const hasSelectedGrandChild = child.children?.some((grandchild) => grandchild.current);

          if (hasSelectedGrandChild) {
            newIsOpen[item.name] = true;
            newIsOpen[child.name] = true;
          } else {
            newIsOpen[child.name] = false;
          }
        });
      });

      return newIsOpen;
    });
  }, [location]);

  const handleDropdownClick = (name) => {
    setIsOpen((prevIsOpen) => ({ ...prevIsOpen, [name]: !prevIsOpen[name] }));
  };

  const navigation = [
    {
      name: "Dashboard",
      href: "/dashboard/",
      icon: IconHome,
      current: location.pathname === "/dashboard/",
    },
    {
      name: "Clientes",
      icon: IconUsers,
      children: [
        {
          name: "Titular",
          href: "/clients/",
          icon: IconUser,
          current: location.pathname === "/clients/",
        },
        {
          name: "Código",
          href: "/shared-token/",
          icon: IconMessageCircleCode,
          current: location.pathname === "/shared-token/",
        },
        {
          name: "Pagos",
          href: "/payments/",
          icon: IconCash,
          current: location.pathname === "/payments/",
        },
      ],
    },
    {
      name: "Equipo",
      href: "/team/",
      icon: IconUsersGroup,
      current: location.pathname === "/team/",
    },
    {
      name: "Reportes",
      href: "/reports/",
      icon: IconReport,
      current: location.pathname === "/reports/",
    },
    {
      name: "Búsqueda de Titulares",
      href: "/search/",
      icon: IconUser,
      current: location.pathname === "/search/",
    },
    {
      name: "Comisiones",
      icon: IconReceiptTax,
      children: [
        {
          name: "Usuarios",
          icon: IconUsers,
          children: [
            {
              name: "Montos de Comisiones",
              href: "/commissions/users",
              icon: IconCurrencyDollar,
              current: location.pathname === "/commissions/users",
            },
            {
              name: "Pagar Comisiones",
              href: "/payment-commissions/users",
              icon: IconCash,
              current: location.pathname === "/payment-commissions/users",
            },
          ],
        },
        {
          name: "Empresas",
          icon: IconBriefcase,
          children: [
						{
							name: "Registro de Empresas",
							href: "/enterprises",
							icon: IconBriefcase,
							current: location.pathname === "/enterprises",
						},
            {
              name: "Montos de Comisiones",
              href: "/commissions/enterprises",
              icon: IconCurrencyDollar,
              current: location.pathname === "/commissions/enterprises",
            },
            {
              name: "Pagar Comisiones",
              href: "/payment-commissions/enterprises",
              icon: IconCash,
              current: location.pathname === "/payment-commissions/enterprises",
            },
          ],
        },
      ],
    },
  ];

  return (
		<div>
			<ul className="rounded-lg max-w-xs w-full px-0">
				{navigation.map((item, index) => (
					<li key={index}>
						{item.children ? (
							<div className='submenu'>
								<div className='p-2 text-sm font-medium text-gray-500 flex justify-between items-center cursor-pointer' onClick={() => handleDropdownClick(item.name)}>
									<span className='flex items-center'>
										<item.icon className='mr-3 flex-shrink-0 h-6 w-6' aria-hidden="true" />
										{item.name}
									</span>
                  <IconChevronDown className={`${isOpen[item.name] ? "open" : "close"} submenu-icon ml-2 flex-shrink-0 h-5 w-5`} aria-hidden="true" />
								</div>
								<ul className={isOpen[item.name] ? "open" : "close"}>
									{item.children.map((child, childIndex) => (
										<li key={childIndex} className='ml-4'>
											{child.children ? (
												<div className="submenu">
													<div className='p-2 text-sm font-medium text-gray-500 flex justify-between items-center cursor-pointer' onClick={() => handleDropdownClick(child.name)}>
														<span className='flex items-center'>
															<child.icon className='mr-5 flex-shrink-0 h-5 w-5' aria-hidden="true" />
															{child.name}
														</span>
                            <IconChevronDown className={`${isOpen[child.name] ? "open" : "close"} submenu-icon ml-2 flex-shrink-0 h-5 w-5`} aria-hidden="true" />
													</div>
													<ul className={isOpen[child.name] ? "open" : "close"}>
														{child.children.map((grandchild, grandchildIndex) => (
															<li key={grandchildIndex} className='ml-4'>
																<NavLink
																	to={grandchild.href}
																	className={`flex items-center p-2 text-sm font-medium rounded-md ${grandchild.current ? 'bg-[#0096C4] text-white' : 'text-gray-500'}`}
																>
																	<grandchild.icon className="mr-2 flex-shrink-0 h-4 w-4" aria-hidden="true" />
																	{grandchild.name}
																</NavLink>
															</li>
														))}
													</ul>
												</div>
											) : (
												<NavLink
													to={child.href}
													className={`flex items-center p-2 text-sm font-medium rounded-md ${child.current ? 'bg-[#0096C4] text-white' : 'text-gray-500'}`}
												>
													<child.icon className="mr-2 flex-shrink-0 h-5 w-5" aria-hidden="true" />
													{child.name}
												</NavLink>
											)}
										</li>
									))}
								</ul>
							</div>
						) : (
							<NavLink
								to={item.href}
								className={`flex items-center p-2 text-sm font-medium rounded-md ${item.current ? 'bg-[#0096C4] text-white' : 'text-gray-500'}`}
							>
								<item.icon className="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
								{item.name}
							</NavLink>
						)}
					</li>
				))}
			</ul>
		</div>
  );
}

export default Sidebar;

