import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import ViewModal from "../modals/ViewModal";
import { IconPencil, IconEye } from '@tabler/icons-react';

function PaymentsCardHorizontal({ data, index }) {

  return (
    <React.Fragment key={index}>
      <tr className="bg-white border-b">
        <td className="p-2 text-xs">
          <div>
            <span className="font-bold">Documento:</span> {data.nuip}
          </div>
          <div>
            <span className="font-bold">Nombres:</span> {data.first_name}{" "}
          </div>
					<div>
            <span className="font-bold">Apellidos:</span> {data.last_name}{" "}
          </div>
        </td>
        <td className="p-2 text-xs">
          <div>
            <span className="font-bold">Monto:</span> {data.price}
          </div>
          <div>
            <span className="font-bold">Método de pago:</span>{" "}
            {data.payment_method}
          </div>
          <div>
            <span className="font-bold">Cód. Factura:</span> {data.invoice_code}
          </div>
        </td>
        <td className="p-2 text-xs space-y-1.5">
          <div>
					<span className="font-bold">F. Factura:</span>{" "}
            {
							data.invoice_date && data.invoice_date ? 
								
							moment(data.invoice_date).format("DD/MM/YYYY")
								:
								"-"
						}
          </div>
          <div>
            <span className="font-bold">F. de Pago:</span>{" "}
            {
							data.payment_date && data.payment_date ?
							moment(data.payment_date).format("DD/MM/YYYY")
							:"-"
						}
          </div>
        </td>
        <td className="p-2 text-xs">
					{
						data.payment_method === "CREDITO POR LIBRANZA" ? ("CREDITO POR LIBRANZA") : (
							<>
							{
								data.img_payment && data.img_payment ? (
						<ViewModal data={data.img_payment} title={"Ver Pago"} icon={<IconEye/>}/>
								) : (
									<span className="font-bold">Sin pago cargado</span>
								)
							}
							</>
						)
					}
        
        </td>
        <td className="p-2 text-xs w-44">
          {data.status === "NO PROCESADO" && (
            <span className="badge badge-error text-white text-xs">
              NO PROCESADO
            </span>
          )}
          {data.status === "EN PROCESO" && (
            <span className="badge badge-warning text-xs">EN PROCESO</span>
          )}
          {data.status === "APROBADO" && (
            <span className="badge badge-success text-xs">APROBADO</span>
          )}
					{data.status === "RECHAZADO" && (
						<span className="badge badge-error text-xs">RECHAZADO</span>
					)}
					{data.status === "APROBADO POR SIAU" && (
						<span className="badge badge-success text-xs">APROBADO POR SIAU</span>
					)}
				</td>
				
        <td className="p-2 text-xs">
          <div className="flex gap-2">
            <div className="tooltip flex" data-tip="Editar">
              <Link
                to={`/edit-payments/${data.id}`}
                className="p-1 bg-yellow-500 text-white rounded-full"
              >
                <IconPencil />
              </Link>
            </div>
          </div>
        </td>
      </tr>

    </React.Fragment>
  );
}
export default PaymentsCardHorizontal;
