import {
	SEARCH_SUCCCESS,
	SEARCH_ERROR
} from "../actions/search/types"

const initialState = {
	search: null,
};

export default function search(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case SEARCH_SUCCCESS:
			return {
				...state,
				search: payload,
			};
		case SEARCH_ERROR:
			return {
				...state,
				search: null,
			};
		default:
			return state;
	}
}