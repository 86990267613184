import { useParams } from "react-router-dom";
import HomeLayout from "../hocs/layout/HomeLayout";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { search_client_nuip } from "../redux/actions/client/client";
import bannerImg from "../assets/img/banner.png";
import bannerSatandard from "../assets/img/banner-standard.png";
import bannerPlus from "../assets/img/banner-plus.png";
import bannerPremium from "../assets/img/banner-premium.png";
import bannerPlatinum from "../assets/img/banner-platinum.png";
import bannerVIP from "../assets/img/banner-vip.png";
import bannerStandardVIP from "../assets/img/banner-standard-vip.png";
import bannerPlusVIP from "../assets/img/banner-plus-vip.png";
import bannerPremiumVIP from "../assets/img/banner-premium-vip.png";
import bannerPlatinumVIP from "../assets/img/banner-platinum-vip.png";
import moment from "moment";
import planStandard from "../assets/img/latir-medal-standard.png";
import planPlus from "../assets/img/latir-medal-plus.png";
import planPremium from "../assets/img/latir-medal-premium.png";
import planPlatinum from "../assets/img/latir-medal-platinum.png";
import planStandardVIP from "../assets/img/latir-medal-standard-vip.png";
import planPlusVIP from "../assets/img/latir-medal-plus-vip.png";
import planPremiumVIP from "../assets/img/latir-medal-premium-vip.png";
import planPlatinumVIP from "../assets/img/latir-medal-platinum-vip.png";
import BeneficiaryModal2 from "../components/modals/BeneficiaryModal2";
import VerifyModal from "../components/modals/VerifyModal";
import HomeNavbar from "../components/navigation/HomeNavbar";
import ChatBubble from "./Chat";

function SearchClientFull({ search_client_nuip, client, loading, error }) {
	const params = useParams();
	const term = params.term;
	const [daysAffiliated, setDaysAffiliated] = useState(0);
	const [beneficiaries, setBeneficiaries] = useState([]);

	useEffect(() => {
		if (client && client.length > 0) {
			const clientData = client[0];
			if (
				clientData.memberships &&
				Array.isArray(clientData.memberships) &&
				clientData.memberships.length > 0
			) {
				const membershipDate = moment(
					clientData.memberships[0].membership_day,
					"YYYY-MM-DD"
				);
				const currentDate = moment();
				const totalDays = currentDate.diff(membershipDate, "days");

				setDaysAffiliated(totalDays);
			}
		}
	}, [client]);

	useEffect(() => {
		if (term) {
			search_client_nuip(term);
		} else if (beneficiaries) {
			search_client_nuip(term);
			setBeneficiaries(false);
		}
	}, [search_client_nuip, term]);

	const handleModalClose = () => {
		setBeneficiaries(true);
	};

	const refreshData = () => {
		search_client_nuip(term);
	};

	return (
		<HomeLayout>
			<HomeNavbar />
			{client && client && client.length > 0 ? (
				client &&
				client.map((client) => (
					<div className="container min-h-screen px-4 mb-5 max-w-3xl mx-auto mt-24 lg:mt-20">
						<div className="bg-white shadow-md shadow-[#bde3f3] rounded-lg mb-5 overflow-hidden">
							<div className="banner-profile">
								{client && client.client_vip === true ? (
									<>
										{client && client.plans
											? (() => {
													switch (client.plans) {
														case "CLASSIC":
															return (
																<img
																	src={bannerStandardVIP}
																	className="w-full"
																	alt="banner"
																/>
															);
														case "PLUS":
															return (
																<img
																	src={bannerPlusVIP}
																	className="w-full"
																	alt="banner"
																/>
															);
														case "PREMIUM":
															return (
																<img
																	src={bannerPremiumVIP}
																	className="w-full"
																	alt="banner"
																/>
															);
														case "PLATINUM":
															return (
																<img
																	src={bannerPlatinumVIP}
																	className="w-full"
																	alt="banner"
																/>
															);
														default:
															return (
																<>
																	<img
																		src={bannerVIP}
																		className="w-full"
																		alt="banner"
																	/>
																</>
															);
													}
											  })()
											: "Sin Especificar"}
									</>
								) : client && client.plans ? (
									(() => {
										switch (client.plans) {
											case "CLASSIC":
												return (
													<img
														src={bannerSatandard}
														className="w-full"
														alt="banner"
													/>
												);
											case "PLUS":
												return (
													<img
														src={bannerPlus}
														className="w-full"
														alt="banner"
													/>
												);
											case "PREMIUM":
												return (
													<img
														src={bannerPremium}
														className="w-full"
														alt="banner"
													/>
												);
											case "PLATINUM":
												return (
													<img
														src={bannerPlatinum}
														className="w-full"
														alt="banner"
													/>
												);
											default:
												return (
													<img
														src={bannerImg}
														className="w-full"
														alt="banner"
													/>
												);
										}
									})()
								) : (
									<img src={bannerImg} className="w-full" alt="banner" />
								)}
							</div>

							<div className="flex flex-col items-center justify-center p-4">
								<div className="flex justify-between w-full">
									<div className="w-full profile-client">
										<div>
											<h2 className="font-bold text-lg text-[#00496B] uppercase">
												{client.last_name}, {client.first_name}
											</h2>
										</div>
										<div className="flex justify-between">
											<div>
												<div>
													<span className="text-sm">
														<span className="font-bold text-[#00496B]">
															Documento:
														</span>{" "}
														{client.document_type}-{client.nuip}
													</span>
												</div>
												<div>
													<span className="text-sm">
														<span className="font-bold text-[#00496B]">
															Género:{" "}
														</span>
														{client && client.gender
															? (() => {
																	switch (client.gender) {
																		case "M":
																			return (
																				<span className="font-normal">
																					Masculino
																				</span>
																			);
																		case "F":
																			return (
																				<span className="font-normal">
																					Femenino
																				</span>
																			);
																		default:
																			return (
																				<span className="font-normal">
																					Otro
																				</span>
																			);
																	}
															  })()
															: "Sin Especificar"}
													</span>
												</div>
											</div>
											<div className="">
												<div>
													<span className="font-bold text-sm text-[#00496B]">
														Estrato:{" "}
													</span>
													<span className="text-sm">{client.stratum}</span>
												</div>
												<div>
													<span className="font-bold text-sm text-[#00496B]">
														Verificado:{" "}
													</span>
													<span className="text-sm">
														{client.token_verified ? "Si" : "No"}
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{client &&
						client.client &&
						client.client.active === true &&
						client.client.status === true ? (
							<>
								<div className="grid grid-cols-1 gap-4 mb-5 md:grid-cols-2">
									<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg">
										<div className="flex flex-col justify-center">
											<span className="font-bold text-[#00496B]">
												Titular del grupo:
											</span>
											<div className="text-sm">
												<div>
													<span className="font-bold text-[#00496B]">
														Nombre:{" "}
													</span>
													{client.client.first_name} {client.client.last_name}
												</div>
												<div>
													<span className="font-bold text-[#00496B]">
														N. de documento:{" "}
													</span>
													{client.client.document_type} - {client.client.nuip}
												</div>
												<div>
													<span className="font-bold text-[#00496B]">
														Verificado:{" "}
													</span>
													{client.client.token_verified ? "Sí" : "No"}
												</div>
											</div>
										</div>
									</div>
									<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg grid grid-cols-3 gap-x-4 place-content-center">
										<div className="font-bold text-center text-[#00496B] text-sm col-span-1">
											{client.client && client.client.client_vip === true ? (
												<>
													{client.client && client.client.plans
														? (() => {
																switch (client.client.plans) {
																	case "CLASSIC":
																		return (
																			<>
																				<div className="flex justify-center">
																					<img
																						src={planStandardVIP}
																						className="w-16"
																						alt="medal standard vip"
																					/>
																				</div>
																				<span className="">STARDARD </span>{" "}
																				<span className="">VIP</span>
																			</>
																		);
																	case "PLUS":
																		return (
																			<>
																				<div className="flex justify-center">
																					<img
																						src={planPlusVIP}
																						className="w-16"
																						alt="medal plus vip"
																					/>
																				</div>
																				<span className="">PLUS </span>{" "}
																				<span className="">VIP</span>
																			</>
																		);
																	case "PREMIUM":
																		return (
																			<>
																				<div className="flex justify-center">
																					<img
																						src={planPremiumVIP}
																						className="w-16"
																						alt="medal premium vip"
																					/>
																				</div>
																				<span className="">PREMIUM </span>{" "}
																				<span className="">VIP</span>
																			</>
																		);
																	case "PLATINUM":
																		return (
																			<>
																				<div className="flex justify-center">
																					<img
																						src={planPlatinumVIP}
																						className="w-16"
																						alt="medal platinum vip"
																					/>
																				</div>
																				<span className="">PLATINUM </span>{" "}
																				<span className="">VIP</span>
																			</>
																		);
																	default:
																		return <>1</>;
																}
														  })()
														: "Sin Especificar"}
												</>
											) : (
												<>
													<div className="flex justify-center">
														{client.client && client.client.plans
															? (() => {
																	switch (client.client.plans) {
																		case "CLASSIC":
																			return (
																				<>
																					<img
																						src={planStandard}
																						className="w-16"
																						alt="medal standard"
																					/>
																				</>
																			);
																		case "PLUS":
																			return (
																				<>
																					<img
																						src={planPlus}
																						className="w-16"
																						alt="medal plus"
																					/>
																				</>
																			);
																		case "PREMIUM":
																			return (
																				<>
																					<img
																						src={planPremium}
																						className="w-16"
																						alt="medal premium"
																					/>
																				</>
																			);
																		case "PLATINUM":
																			return (
																				<>
																					<img
																						src={planPlatinum}
																						className="w-16"
																						alt="medal platinum"
																					/>
																				</>
																			);
																		default:
																			return <>1</>;
																	}
															  })()
															: "Sin Especificar"}
													</div>
													<span className="">Plan </span>{" "}
													{client.client && client.client.plans
														? (() => {
																switch (client.client.plans) {
																	case "CLASSIC":
																		return <span className="">Standard</span>;
																	case "PLUS":
																		return <span className="">Plus</span>;
																	case "PREMIUM":
																		return <span className="">Premium</span>;
																	case "PLATINUM":
																		return <span className="">Platinum</span>;
																	default:
																		return (
																			<span className="">Sin especificar</span>
																		);
																}
														  })()
														: "Sin Especificar"}
												</>
											)}
										</div>
										<div className="col-span-2">
											<span className="font-bold text-[#00496B]">
												Beneficios del plan
											</span>
											<div className="flex justify-center">
												{client.client && client.client.plans
													? (() => {
															switch (client.client.plans) {
																case "CLASSIC":
																	return (
																		<div>
																			<ul className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 list-disc pl-4">
																				<li className="py-0.5 text-xs">
																					50 % descuento en consultas
																					adicionales{" "}
																				</li>
																				<li className="py-0.5 text-xs">
																					⁠10 % descuento en demás servicios de
																					la Culm
																				</li>
																			</ul>
																		</div>
																	);
																case "PLUS":
																	return (
																		<div>
																			<ul className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 list-disc pl-4">
																				<li className="py-0.5 text-xs">
																					70 % descuento en consultas
																				</li>
																				<li className="py-0.5 text-xs">
																					⁠Hasta 25%. Descuento en demás
																					servicios Culm
																				</li>
																			</ul>
																		</div>
																	);
																case "PREMIUM":
																	return (
																		<div>
																			<ul className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 list-disc pl-4">
																				<li className="py-0.5 text-xs">
																					70 % descuento en consultas
																				</li>
																				<li className="py-0.5 text-xs">
																					⁠Hasta 25%. Descuento en demás
																					servicios Culm
																				</li>
																			</ul>
																		</div>
																	);
																case "PLATINUM":
																	return (
																		<div>
																			<ul className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 list-disc pl-4">
																				<li className="py-0.5 text-xs">
																					70 % descuento en consultas
																				</li>
																				<li className="py-0.5 text-xs">
																					⁠Hasta 25%. Descuento en demás
																					servicios Culm
																				</li>
																			</ul>
																		</div>
																	);
																default:
																	return <>1</>;
															}
													  })()
													: "Sin Especificar"}
											</div>
										</div>
									</div>
									<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg">
										<div>
											<span className="font-bold text-[#00496B]">
												Beneficiarios del grupo:
											</span>
										</div>
										<div>
											{client.client.beneficiaries &&
												client.client.beneficiaries.map((beneficiary) => (
													<ul className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
														<li className="px-2 py-1">
															{beneficiary.first_name} {beneficiary.last_name}
														</li>
													</ul>
												))}
										</div>
									</div>
								</div>
							</>
						) : client.token_verified === true ? (
							<>
								{client.memberships && client.memberships.length >= 1 ? (
									<>
										<div className="grid grid-cols-1 gap-4 mb-5 md:grid-cols-2">
											<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg">
												<div>
													<span className="font-bold text-[#00496B]">
														Datos adicionales:
													</span>
												</div>
												<div className="flex flex-col justify-center">
													{client.memberships.map((membership, index) => (
														<div
															key={index}
															className="flex flex-col justify-center"
														>
															<div>
																<span className="font-bold text-[#00496B]">
																	F. Afiliación:
																</span>{" "}
																{membership.membership_day
																	? moment(membership.membership_day).format(
																			"DD/MM/YYYY"
																	  )
																	: "-"}
															</div>
															<div>
																<span className="font-bold text-[#00496B]">
																	F. Expiración:
																</span>{" "}
																{membership.expiry_date
																	? moment(membership.expiry_date).format(
																			"DD/MM/YYYY"
																	  )
																	: "-"}
															</div>
															{/* Aquí puedes agregar más información de cada membresía si es necesario */}
														</div>
													))}
													<div>
														{client && client.client ? (
															<></>
														) : (
															<>
																<span className="font-bold text-[#00496B]">
																	Días:
																</span>{" "}
																{daysAffiliated}{" "}
																{daysAffiliated === 1 ? "día" : "días"}
															</>
														)}
													</div>
												</div>
											</div>
											<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg grid grid-cols-3 gap-x-4 place-content-center">
												<div className="font-bold text-center text-[#00496B] text-sm col-span-1">
													{client && client.client_vip === true ? (
														<>
															{client && client.plans
																? (() => {
																		switch (client.plans) {
																			case "CLASSIC":
																				return (
																					<>
																						<div className="flex justify-center">
																							<img
																								src={planStandardVIP}
																								className="w-16"
																								alt="medal standard vip"
																							/>
																						</div>
																						<span className="">STARDARD </span>{" "}
																						<span className="">VIP</span>
																					</>
																				);
																			case "PLUS":
																				return (
																					<>
																						<div className="flex justify-center">
																							<img
																								src={planPlusVIP}
																								className="w-16"
																								alt="medal plus vip"
																							/>
																						</div>
																						<span className="">PLUS </span>{" "}
																						<span className="">VIP</span>
																					</>
																				);
																			case "PREMIUM":
																				return (
																					<>
																						<div className="flex justify-center">
																							<img
																								src={planPremiumVIP}
																								className="w-16"
																								alt="medal premium vip"
																							/>
																						</div>
																						<span className="">PREMIUM </span>{" "}
																						<span className="">VIP</span>
																					</>
																				);
																			case "PLATINUM":
																				return (
																					<>
																						<div className="flex justify-center">
																							<img
																								src={planPlatinumVIP}
																								className="w-16"
																								alt="medal platinum vip"
																							/>
																						</div>
																						<span className="">PLATINUM </span>{" "}
																						<span className="">VIP</span>
																					</>
																				);
																			default:
																				return <>1</>;
																		}
																  })()
																: "Sin Especificar"}
														</>
													) : (
														<>
															<div className="flex justify-center">
																{client && client.plans
																	? (() => {
																			switch (client.plans) {
																				case "CLASSIC":
																					return (
																						<>
																							<img
																								src={planStandard}
																								className="w-16"
																								alt="medal standard"
																							/>
																						</>
																					);
																				case "PLUS":
																					return (
																						<>
																							<img
																								src={planPlus}
																								className="w-16"
																								alt="medal plus"
																							/>
																						</>
																					);
																				case "PREMIUM":
																					return (
																						<>
																							<img
																								src={planPremium}
																								className="w-16"
																								alt="medal premium"
																							/>
																						</>
																					);
																				case "PLATINUM":
																					return (
																						<>
																							<img
																								src={planPlatinum}
																								className="w-16"
																								alt="medal platinum"
																							/>
																						</>
																					);
																				default:
																					return <>1</>;
																			}
																	  })()
																	: "Sin Especificar"}
															</div>
															<span className="">Plan </span>{" "}
															{client && client.plans
																? (() => {
																		switch (client.plans) {
																			case "CLASSIC":
																				return (
																					<span className="">Standard</span>
																				);
																			case "PLUS":
																				return <span className="">Plus</span>;
																			case "PREMIUM":
																				return (
																					<span className="">Premium</span>
																				);
																			case "PLATINUM":
																				return (
																					<span className="">Platinum</span>
																				);
																			default:
																				return (
																					<span className="">
																						Sin especificar
																					</span>
																				);
																		}
																  })()
																: "Sin Especificar"}
														</>
													)}
												</div>
												<div className="col-span-2">
													<span className="font-bold text-[#00496B]">
														Beneficios del plan
													</span>
													<div className="flex justify-center">
														{client && client.plans
															? (() => {
																	switch (client.plans) {
																		case "CLASSIC":
																			return (
																				<div>
																					<ul className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 list-disc pl-4">
																						<li className="py-0.5 text-xs">
																							50 % descuento en consultas
																							adicionales{" "}
																						</li>
																						<li className="py-0.5 text-xs">
																							⁠10 % descuento en demás servicios
																							de la Culm
																						</li>
																					</ul>
																				</div>
																			);
																		case "PLUS":
																			return (
																				<div>
																					<ul className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 list-disc pl-4">
																						<li className="py-0.5 text-xs">
																							70 % descuento en consultas
																						</li>
																						<li className="py-0.5 text-xs">
																							⁠Hasta 25%. Descuento en demás
																							servicios Culm
																						</li>
																					</ul>
																				</div>
																			);
																		case "PREMIUM":
																			return (
																				<div>
																					<ul className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 list-disc pl-4">
																						<li className="py-0.5 text-xs">
																							70 % descuento en consultas
																						</li>
																						<li className="py-0.5 text-xs">
																							⁠Hasta 25%. Descuento en demás
																							servicios Culm
																						</li>
																					</ul>
																				</div>
																			);
																		case "PLATINUM":
																			return (
																				<div>
																					<ul className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 list-disc pl-4">
																						<li className="py-0.5 text-xs">
																							70 % descuento en consultas
																						</li>
																						<li className="py-0.5 text-xs">
																							⁠Hasta 25%. Descuento en demás
																							servicios Culm
																						</li>
																					</ul>
																				</div>
																			);
																		default:
																			return <>1</>;
																	}
															  })()
															: "Sin Especificar"}
													</div>
												</div>
											</div>
										</div>
										<div className="grid grid-cols-1 gap-4 md:grid-cols-2">
											<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg">
												{client && client.client ? (
													<>
														<div>
															<span className="font-bold text-[#00496B]">
																Titular
															</span>
															<ul>
																<li className="px-2 py-1">
																	{client.client.first_name}{" "}
																	{client.client.last_name}
																</li>
															</ul>
														</div>
													</>
												) : (
													<>
														<div>
															<span className="font-bold text-[#00496B]">
																Beneficiarios
															</span>
														</div>
														<div>
															{client.beneficiaries &&
																client.beneficiaries.map((beneficiary) => (
																	<ul className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
																		<li className="px-2 py-1">
																			{beneficiary.first_name}{" "}
																			{beneficiary.last_name}
																		</li>
																	</ul>
																))}
															<div className="flex justify-center">
																{moment().diff(
																	moment(client.membership_day),
																	"months"
																) === 1 ||
																(client.beneficiaries &&
																	client.beneficiaries.length >= 3) ? (
																	<></>
																) : (
																	<BeneficiaryModal2
																		client={client.id}
																		onClose={handleModalClose}
																		refreshData={refreshData}
																	/>
																)}
															</div>
														</div>
													</>
												)}
											</div>
											<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg">
												<div>
													<span className="font-bold text-[#00496B]">
														Consultas y Bonos
													</span>
												</div>
												<div className="mt-2 text-sm text-gray-900">
													{
														client.number_queries > 0 && (
															<div>
																<span className="font-bold text-[#00496B]">
																	Consultas:{" "}
																</span>
																{client.number_queries}
															</div>
														)
													}
													{
														client.bonus_culm > 0 && (
															<div>
																<span className="font-bold text-[#00496B]">
																	Bonos Culm:{" "}
																</span>
																{client.bonus_culm}
															</div>
														) 
													}
													{client.bonus_corpoindustrial > 0 && (
														<div>
															<span className="font-bold text-[#00496B]">
																Bonos Corpoindustrial:{" "}
															</span>
															{client.bonus_corpoindustrial}
														</div>
													)}
												</div>
											</div>
										</div>
									</>
								) : (
									<>
										<div className="bg-white shadow-md shadow-[#bde3f3] rounded-lg mb-5 overflow-hidden">
											<div className="p-4">
												<div>
													<p className="text-sm">
														Felicidades, ya estás verificado y estamos validando
														tu pago, por favor, espera para poder disfrutar del
														servicio de latir.
													</p>
												</div>
											</div>
										</div>
									</>
								)}
							</>
						) : client.status === false && client.active === false ? (
							<>
								<div className="bg-white shadow-md shadow-[#bde3f3] rounded-lg mb-5 overflow-hidden">
									<div className="p-4">
										<div>
											<p className="text-sm">
												Lo sentimos, este usuario no se encuentra activo en la
												plataforma.
											</p>
										</div>
									</div>
								</div>
							</>
						) : client.client &&
						  client.client.active === false &&
						  client.client.status === false ? (
							<>
								<div className="bg-white shadow-md shadow-[#bde3f3] rounded-lg mb-5 overflow-hidden">
									<div className="p-4">
										<div>
											<p className="text-sm">
												Lo sentimos, este usuario no se encuentra activo en la
												plataforma.
											</p>
										</div>
									</div>
								</div>
							</>
						) : (
							<>
								<div className="bg-white shadow-md shadow-[#bde3f3] rounded-lg mb-5 overflow-hidden">
									<div className="p-4">
										<div>
											<p className="text-sm">
												No te encuentras verificado, por favor, pulsa el
												siguiente botón para verificar tu cuenta con el código
												que te fue enviado.
											</p>
										</div>
										<div className="flex justify-center mt-2">
											<VerifyModal data={client.id} />
										</div>
									</div>
								</div>
							</>
						)}
					</div>
				))
			) : (
				<>
					<div className="container min-h-screen max-w-3xl mx-auto mt-28 text-center">
						<span className="font-bold text-xl text-[#00496B]">
							No se encontraron resultados
						</span>
					</div>
				</>
			)}
			<ChatBubble />
		</HomeLayout>
	);
}

const mapStateToProps = (state) => ({
	client: state.clients.filtered_clients,
	error: state.clients.error,
});

export default connect(mapStateToProps, {
	search_client_nuip,
})(SearchClientFull);
