// BrowserRouter as Router comentado
import {
  Route,
  Router,
  Routes,
  useLocation,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Error404 from "../../containers/errors/Error404";
// import Home from "../../containers/Home";
import ClientVerify from "../../containers/ClientVerify";
import LoginUser from "../../containers/LoginUser";
import Dashboard from "../../containers/Dashboard";
import PrivateRoute from "./PrivateRoutes";
import Clients from "../../containers/pages/client/Clients";
import ClientForm from "../../containers/pages/client/ClientForm";
import ClientEdit from "../../containers/pages/client/ClientEdit";
import BeneficiarieEdit from "../../containers/pages/client/BeneficiarieEdit";
import ReferredForm from "../../containers/pages/client/ReferredForm";
import SharedToken from "../../containers/pages/sharedtoken/SharedToken";
import SearchClient from "../../containers/pages/client/SearchClient";
import SearchToken from "../../containers/pages/sharedtoken/SearchToken";
import Payments from "../../containers/pages/payment/Payments";
import PaymentsEdit from "../../containers/pages/payment/PaymentsEdit";
import Profile from "../../containers/pages/users/Profile";
import SearchClientFull from "../../containers/SearchClientFull";
import SearchPayments from "../../containers/pages/payment/SearchPayments";
import ClientReport from "../../containers/pages/report/ClientReport";
import Users from "../../containers/pages/users/Users";
import ResetPassword from "../../containers/auth/ResetPassword";
import ResetPasswordConfirm from "../../containers/auth/ResetPasswordConfirm";
import ActivateAccount from "../../containers/auth/ActivateAccount";
import UsersForm from "../../containers/pages/users/UsersForm";
import UserEdit from "../../containers/pages/users/UserEdit";
import Reports from "../../containers/pages/report/Reports";
import Search from "../../containers/pages/search/Search";

import ChatRoom from "../../containers/ChatRoom";
import DashboardDetail from "../../containers/DashboardDetail";
import SearchUser from "../../containers/pages/users/SearchUser";

import UsersCommissions from "../../containers/pages/userscommissions/Commissions";
import UsersCommissionsForm from "../../containers/pages/userscommissions/CommissionsForm";
import UsersCommissionsEdit from "../../containers/pages/userscommissions/CommissionsEdit";
import UsersCommissionsReport from "../../containers/pages/report/UsersCommissionsReport";
import UsersPaymentCommissions from "../../containers/pages/userscommissions/PaymentCommissions";
import UsersSearchCommissions from "../../containers/pages/userscommissions/SearchCommissions";
import UsersSearchCommissionsPayment from "../../containers/pages/userscommissions/SearchCommissionsPayment";

import EnterprisesCommissions from "../../containers/pages/enterprisescommissions/Commissions";
import EnterprisesCommissionsForm from "../../containers/pages/enterprisescommissions/CommissionsForm";
import EnterprisesCommissionsEdit from "../../containers/pages/enterprisescommissions/CommissionsEdit";
import EnterprisesCommissionsReport from "../../containers/pages/report/EnterprisesCommissionsReport";
import EnterprisesPaymentCommissions from "../../containers/pages/enterprisescommissions/PaymentCommissions";
import EnterprisesSearchCommissions from "../../containers/pages/enterprisescommissions/SearchCommissions";
import EnterprisesSearchCommissionsPayment from "../../containers/pages/enterprisescommissions/SearchCommissionsPayment";
import Enterprise from "../../containers/pages/enterprise/Enterprise";
import EnterpriseForm from "../../containers/pages/enterprise/EnterpriseForm";
import EnterpriseEdit from "../../containers/pages/enterprise/EnterpriseEdit";

function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        {/* Error Display */}
        <Route path="*" element={<Error404 />} />

        {/* Home Display */}
        <Route path="/" element={<ClientVerify />} />
        <Route path="/login" element={<LoginUser />} />
				<Route path="/forgot_password" element={<ResetPassword />} />
				<Route
					path="/password/reset/confirm/:uid/:token"
					element={<ResetPasswordConfirm />}
				/>
				<Route path="/active/:uid/:token" element={<ActivateAccount />} />
        {/* Inicio dash */}

        <Route path="/dashboard" element={
				<PrivateRoute>
				<Dashboard />
				</PrivateRoute>} />
				
				<Route path="/dashboard/:id" element={
				<PrivateRoute>
				<DashboardDetail />
				</PrivateRoute>} />
        <Route path="/clients" element={
				<PrivateRoute>
				<Clients />
				</PrivateRoute> } />

        <Route path="/create-clients" element={
				<PrivateRoute>
					<ClientForm />
					</PrivateRoute>
				} />
        
				<Route path="/edit-clients/:id" element={
				<PrivateRoute>
				<ClientEdit />
				</PrivateRoute>} />

        <Route path="/search/clients/:term" element={
				<PrivateRoute>
				<SearchClient />
				</PrivateRoute>} />

				<Route path="/search/client/full/:term" element={

				<SearchClientFull />}/>
				
        <Route path="/edit-beneficiaries/:id" element={
				<PrivateRoute>
				<BeneficiarieEdit />
				</PrivateRoute>} />

        <Route path="/create-referred" element={
				<PrivateRoute>
				<ReferredForm />
				</PrivateRoute>} />

        <Route path="/shared-token" element={
				<PrivateRoute>
				<SharedToken />
				</PrivateRoute>} />

        <Route path="/search/token/:term" element={
				<PrivateRoute>
				<SearchToken />
				</PrivateRoute>} />

        <Route path="/payments" element={
				<PrivateRoute>
				<Payments />
				</PrivateRoute>} />


        <Route path="/edit-payments/:id" element={
				<PrivateRoute>
				<PaymentsEdit />
				</PrivateRoute>} />

				<Route path="/search/payments/:term" element={
				<PrivateRoute>
				<SearchPayments />
				</PrivateRoute>} />

        <Route path="/profile" element={
				<PrivateRoute>
				<Profile />
				</PrivateRoute>} />
				
				<Route path="/team" element={
				<PrivateRoute>
				<Users />
				</PrivateRoute>
				} />

				<Route path="/search/users/:term" element={
				<PrivateRoute>
				<SearchUser />
				</PrivateRoute>} />
				
				<Route path="/create-users" element={
				<PrivateRoute>
				<UsersForm />
				</PrivateRoute>
				} />
				
				<Route path="/edit-users/:id" element={
				<PrivateRoute>
				<UserEdit />
				</PrivateRoute>} />
				
				<Route path="/reports" element={
				<PrivateRoute>
				<Reports/>
				</PrivateRoute>} />

        <Route path="/client-report/:id" element={
				<PrivateRoute>
				<ClientReport />
				</PrivateRoute>} />

				<Route path="/search" element={
				<PrivateRoute>
				<Search />
				</PrivateRoute>} />

				<Route path="/chat"  element={
				<PrivateRoute>
				<ChatRoom/>
				</PrivateRoute>} />
				
				<Route path="/commissions/users" element={
				<PrivateRoute>
				<UsersCommissions />
				</PrivateRoute>} />
				
				<Route path="/search/commission/users/:term" element={
				<PrivateRoute>
				<UsersSearchCommissions />
				</PrivateRoute>} />

				<Route path="/commissions-create/users" element={
				<PrivateRoute>
				<UsersCommissionsForm />
				</PrivateRoute>} />

				<Route path="/commissions-edit/users/:id" element={
				<PrivateRoute>
				<UsersCommissionsEdit />
				</PrivateRoute>} />
				
				<Route path="/payment-commissions/users" element={
				<PrivateRoute>
				<UsersPaymentCommissions />
				</PrivateRoute>} />

				<Route path="/commissions-report/users/:id" element={
				<PrivateRoute>
				<UsersCommissionsReport />
				</PrivateRoute>} />
				
				<Route path="/search/commission/users/payment/:term" element={
				<PrivateRoute>
				<UsersSearchCommissionsPayment />
				</PrivateRoute>} />

				<Route path="/commissions/enterprises" element={
				<PrivateRoute>
				<EnterprisesCommissions />
				</PrivateRoute>} />
				
				<Route path="/search/commission/enterprises/:term" element={
				<PrivateRoute>
				<EnterprisesSearchCommissions />
				</PrivateRoute>} />

				<Route path="/commissions-create/enterprises" element={
				<PrivateRoute>
				<EnterprisesCommissionsForm />
				</PrivateRoute>} />

				<Route path="/commissions-edit/enterprises/:id" element={
				<PrivateRoute>
				<EnterprisesCommissionsEdit />
				</PrivateRoute>} />
				
				<Route path="/payment-commissions/enterprises" element={
				<PrivateRoute>
				<EnterprisesPaymentCommissions />
				</PrivateRoute>} />

				<Route path="/commissions-report/enterprises/:id" element={
				<PrivateRoute>
				<EnterprisesCommissionsReport />
				</PrivateRoute>} />
				
				<Route path="/search/commission/enterprises/payment/:term" element={
				<PrivateRoute>
				<EnterprisesSearchCommissionsPayment />
				</PrivateRoute>} />
				
				<Route
				path="/enterprises"
				element={<Enterprise />}
				/>

				<Route
				path="/enterprises/form"
				element={<EnterpriseForm />}
				/>

				<Route
				path="/enterprises/edit/:uid"
				element={<EnterpriseEdit />}
				/>
      </Routes>
    </AnimatePresence>
  );
}
export default AnimatedRoutes;
