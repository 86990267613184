import {
	SEARCH_SUCCCESS,
	SEARCH_ERROR
} from "./types"

import axios from "axios";

export const get_search = (nuip) => async (dispatch) => {
	const config = {
    headers: {
      Accept: "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  try {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/search/?nuip=${nuip}`,
			config
		);
		if (res.status === 200) {
			dispatch({
				type: SEARCH_SUCCCESS,
				payload: res.data,
			});
		} else {
			dispatch({
				type: SEARCH_ERROR,
			});
		}
	} catch {
		dispatch({
			type: SEARCH_ERROR,
		});
	}
};