import React, { useState } from "react";
import { 
  IconSend
} from '@tabler/icons-react';
import DeleteClient from "../modals/DeleteClient";

function MembershipRenewModal({
  data,
  handleButtonClick,
  isButtonDisabled,
  onSubmitDeleteClient
}) {
	const [openMembershipRenew, setOpenMembershipRenew] = useState(false);
	const [openModalDelete, setOpenModalDelete] = useState(false);

  return (
    <div>
      <div
        className="flex tooltip"
        data-tip="Renovar membresia"
      >
        <label 
          htmlFor="membership_renew_modal"
          className={`p-1 text-white bg-green-500 rounded-full cursor-pointer ${isButtonDisabled ? "disabled" : ""}`}
          disabled={isButtonDisabled}
        >
          <IconSend />
        </label>
      </div>

      <input type="checkbox" id="membership_renew_modal" className="modal-toggle" />
      <div className="modal" role="dialog">
        <div className="modal-box rounded-md">
          <h3 className="font-medium text-2xl text-gray-900 text-center">Renovar membresía</h3>
          <div className="my-2">
            <p className="text-xl text-center mb-2 text-gray-500">
              ¿Deseas renovar la membresía de {data.first_name}?
            </p>
            <p className="text-gray-500 text-center">Pulsa "Sí" para renovar la membresía del cliente, pulsa "No" para desactivarlo</p>
          </div>
          <div className="modal-action justify-center">
            <div className="flex justify-center gap-x-2">

              <label
                onClick={(e) => (handleButtonClick(e, data.nuip), document.getElementById('membership_renew_modal').checked = false)}
                className="btn border w-20 bg-[#0096C4] text-white"
              >
                Sí
              </label>
              <DeleteClient data={data} onSubmitDeleteClient={onSubmitDeleteClient} type={"titular"}/>
            </div>
          </div>
        </div>
        <label className="modal-backdrop" htmlFor="membership_renew_modal">Close</label>
      </div>
    </div>
  );
}

export default MembershipRenewModal;
