import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get_search } from "../../../redux/actions/search/search";
import bannerImg from "../../../assets/img/banner.png";
import bannerStandard from "../../../assets/img/banner-standard.png";
import bannerPlus from "../../../assets/img/banner-plus.png";
import bannerPremium from "../../../assets/img/banner-premium.png";
import bannerPlatinum from "../../../assets/img/banner-platinum.png";
import bannerVIP from "../../../assets/img/banner-vip.png";
import bannerStandardVIP from "../../../assets/img/banner-standard-vip.png";
import bannerPlusVIP from "../../../assets/img/banner-plus-vip.png";
import bannerPremiumVIP from "../../../assets/img/banner-premium-vip.png";
import bannerPlatinumVIP from "../../../assets/img/banner-platinum-vip.png";
import planStandard from "../../../assets/img/latir-medal-standard.png";
import planPlus from "../../../assets/img/latir-medal-plus.png";
import planPremium from "../../../assets/img/latir-medal-premium.png";
import planPlatinum from "../../../assets/img/latir-medal-platinum.png";
import planStandardVIP from "../../../assets/img/latir-medal-standard-vip.png";
import planPlusVIP from "../../../assets/img/latir-medal-plus-vip.png";
import planPremiumVIP from "../../../assets/img/latir-medal-premium-vip.png";
import planPlatinumVIP from "../../../assets/img/latir-medal-platinum-vip.png";
import axios from "axios";
import moment from "moment";
import Toast from "../../../components/Alert";
import { Dialog, Transition } from "@headlessui/react";
import SearchModal from "../../../components/modals/SearchModal";
import BeneficiaryModal2 from "../../../components/modals/BeneficiaryModal2";
import { IconFileDescription, IconTrash } from "@tabler/icons-react";
function Search({ get_search, search }) {
	
	const [nuip, setNuip] = useState("");
	const [updateNumberQueries, setUpdateNumberQueries] = useState(false);
	const [
		updateNumberQueriesBeneficiaryId,
		setUpdateNumberQueriesBeneficiaryId,
	] = useState(null);

	const [updateDiscountCulm, setUpdateDiscountCulm] = useState(false);
	const [loading, setLoading] = useState(false);

	const [showDiscountFields, setShowDiscountFields] = useState(false);

	const [showDiscounts, setShowDiscounts] = useState(false);
	const handleInputChange = (e) => {
		setNuip(e.target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		get_search(nuip);
	};

	useEffect(() => {
		if (showDiscounts) {
			get_search(nuip);
			setShowDiscounts(false);
		}
	}, [showDiscounts]);
	const [formData, setFormData] = useState({
		number_queries: "",
		type: "",
		value: "",
		discount: "",
		invoice_code: "",
	});

	const { number_queries, type, value, discount, invoice_code } = formData;

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = (e, id, nuip) => {
		e.preventDefault();
		if (number_queries < 0) {
			Toast({
				tipo: "error",
				mensaje: "El número de consultas no puede ser negativo.",
			});
			return;
		} else if (number_queries > 1) {
			Toast({
				tipo: "error",
				mensaje: "El número de consultas no puede ser mayor a 1.",
			});
			return;
		}
		const config = {
			headers: {
				Accept: "application/json",
				"Content-Type": "multipart/form-data",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};
		const formData = new FormData();
		formData.append("number_queries", number_queries);
		formData.append("id", id);
		const fetchData = async () => {
			setLoading(true);
			try {
				const res = await axios.put(
					`${process.env.REACT_APP_API_URL}/client/edit/${id}/`,
					formData,
					config
				);
				if (res.status === 200) {
					setFormData({
						number_queries: "",
					});
					setLoading(false);
					setUpdateNumberQueries(false);
					get_search(nuip);
					Toast({ tipo: "success", mensaje: res.data.success });
				} else {
					Toast({ tipo: "error", mensaje: res.data.error });
				}
			} catch (err) {
				Toast({ tipo: "error", mensaje: "Error al editar el pago" });
			}
		};
		fetchData();
	};

	const onSubmitBeneficiary = (e, id) => {
		e.preventDefault();
		if (number_queries < 0) {
			Toast({
				tipo: "error",
				mensaje: "El número de consultas no puede ser negativo.",
			});
			return;
		} else if (number_queries > 1) {
			Toast({
				tipo: "error",
				mensaje: "El número de consultas no puede ser mayor a 1.",
			});
			return;
		}
		const config = {
			headers: {
				Accept: "application/json",
				"Content-Type": "multipart/form-data",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};
		const formData = new FormData();
		formData.append("number_queries", number_queries);
		formData.append("id", id);
		const fetchData = async () => {
			setLoading(true);
			try {
				const res = await axios.put(
					`${process.env.REACT_APP_API_URL}/beneficiary/edit/${id}/`,
					formData,
					config
				);
				if (res.status === 200) {
					setFormData({
						number_queries: "",
					});
					setLoading(false);
					setUpdateNumberQueriesBeneficiaryId(false);
					get_search(nuip);
					Toast({ tipo: "success", mensaje: res.data.success });
				} else {
					Toast({ tipo: "error", mensaje: res.data.error });
				}
			} catch (err) {
				Toast({
					tipo: "error",
					mensaje: "Error al editar el número de consulta.",
				});
			}
		};
		fetchData();
	};

	const onSubmitDiscount = (e, id) => {
		e.preventDefault();
		const config = {
			headers: {
				Accept: "application/json",
				"Content-Type": "multipart/form-data",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};
		const formData = new FormData();
		formData.append("type", type);
		formData.append("value", value);
		formData.append("discount", discount);
		formData.append("invoice_code", invoice_code);
		formData.append("client_id", id);

		const fetchData = async () => {
			setLoading(true);
			try {
				const res = await axios.post(
					`${process.env.REACT_APP_API_URL}/search/create/`,
					formData,
					config
				);
				if (res.status === 200) {
					setFormData({
						type: "",
						value: "",
						discount: "",
						invoice_code: "",
					});
					setLoading(false);
					setShowDiscountFields(false);
					setShowDiscounts(true);
					Toast({ tipo: "success", mensaje: res.data.success });
				} else {
					Toast({ tipo: "error", mensaje: res.data.error });
				}
			} catch (err) {
				Toast({ tipo: "error", mensaje: "Error al crear el descuento" });
			}
		};

		fetchData();
	};
	
	const handleModalClose = () => {
		setShowDiscounts(true);
	};

	return (
		<DashboardLayout>
			<div className="border-b border-gray-200 bg-white py-5 sm:px-6">
				<div className="flex flex-col flex-wrap lg:flex-row lg:items-center justify-between sm:flex-nowrap">
					<div className="">
						<h3 className="text-2xl font-medium leading-6 text-gray-900">
							Buscar Titulares
						</h3>
					</div>
					<div className="flex flex-col lg:flex-row lg:items-center gap-4 py-2 lg:py-0">
						<div className="flex justify-between gap-4">
							<div className=""></div>
							<div className=""></div>
						</div>
						<div className="">
							<div className="flex items-center md:max-w-3xl md:mx-auto lg:max-w-none">
								<form onSubmit={handleSubmit} className="w-full">
									<label htmlFor="search" className="sr-only">
										Buscar
									</label>
									<div className="relative">
										<button
											type="submit"
											className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth={1.5}
												stroke="currentColor"
												className="w-6 h-6"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
												/>
											</svg>
										</button>
										<input
											id="nuip"
											name="nuip"
											required
											value={nuip}
											onChange={handleInputChange}
											className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
											placeholder="Buscar"
											type="search"
										/>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			{search && search.length > 0 ? (
				<div>
					{search.map((item) => (
						<div
							key={item.id}
							className="container px-4 mb-5 max-w-3xl mx-auto mt-6 lg:mt-10"
						>
							<div className="bg-white shadow-md shadow-[#bde3f3] rounded-lg mb-5 overflow-hidden">
								<div className="banner-profile">
									{item.client && item.client.client_vip === true ? (
										<>
											{item.client && item.client.plans ? (() => {
												switch (item.client.plans) {
													case "CLASSIC":
														return (
															<img src={bannerStandardVIP} className="w-full" alt="banner" />
														);
													case "PLUS":
														return (
															<img src={bannerPlusVIP} className="w-full" alt="banner" />
														);
													case "PREMIUM":
														return (
															<img src={bannerPremiumVIP} className="w-full" alt="banner" />
														);
													case "PLATINUM":
														return (
															<img src={bannerPlatinumVIP} className="w-full" alt="banner" />
														);
													default:
														return <>
															<img src={bannerVIP} className="w-full" alt="banner" />
														</>;
												}
											}
											)() : "Sin Especificar"}
										</>
									) : item.client.plans ? (
										(() => {
											switch (item.client.plans) {
												case "CLASSIC":
													return (
														<img
															src={bannerStandard}
															className="w-full"
															alt="banner"
														/>
													);
												case "PLUS":
													return (
														<img
															src={bannerPlus}
															className="w-full"
															alt="banner"
														/>
													);
												case "PREMIUM":
													return (
														<img
															src={bannerPremium}
															className="w-full"
															alt="banner"
														/>
													);
												case "PLATINUM":
													return (
														<img
															src={bannerPlatinum}
															className="w-full"
															alt="banner"
														/>
													);
												default:
													return (
														<img
															src={bannerImg}
															className="w-full"
															alt="banner"
														/>
													);
											}
										})()
									) : (
										<img src={bannerImg} className="w-full" alt="banner" />
									)}
								</div>
								<div className="flex flex-col items-center justify-center p-4">
									<div className="flex justify-between w-full">
										<div className="w-full profile-client">
											<div>
												<h2 className="font-bold text-lg text-[#00496B] uppercase">
													{item.client.last_name}, {item.client.first_name}
												</h2>
											</div>
											<div className="flex justify-between">
												<div>
													<div>
														<span className="text-sm">
															<span className="font-bold text-[#00496B]">
																Documento:
															</span>{" "}
															{item.client.document_type}-{item.client.nuip}
														</span>
													</div>
													<div>
														<span className="text-sm">
															<span className="font-bold text-[#00496B]">
																Género:{" "}
															</span>
															{item.client && item.client.gender
																? (() => {
																		switch (item.client.gender) {
																			case "M":
																				return (
																					<span className="font-normal">
																						Masculino
																					</span>
																				);
																			case "F":
																				return (
																					<span className="font-normal">
																						Femenino
																					</span>
																				);
																			default:
																				return (
																					<span className="font-normal">
																						Otro
																					</span>
																				);
																		}
																  })()
																: "Sin Especificar"}
														</span>
													</div>
												</div>
												<div className="">
													<div>
														<span className="font-bold text-sm text-[#00496B]">
															Estrato:{" "}
														</span>
														<span className="text-sm">
															{item.client.stratum}
														</span>
													</div>
													<div>
														<span className="font-bold text-sm text-[#00496B]">
															Verificado:{" "}
														</span>
														<span className="text-sm">
															{item.client.token_verified ? "Si" : "No"}
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{item.client.memberships &&
								item.client.memberships.length >= 1 && item.client.status && item.client.active ? (
									<>
										<div className="grid grid-cols-1 gap-4 mb-5 md:grid-cols-2">
											<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg">
												<div>
													<span className="font-bold text-[#00496B]">
														Datos adicionales:
													</span>
												</div>
												<div className="flex flex-col justify-center">
													{item.client.memberships.map((membership, index) => (
														<div
															key={index}
															className="flex flex-col justify-center"
														>
															<div>
																<span className="font-bold text-[#00496B]">
																	F. Afiliación:
																</span>{" "}
																{membership.membership_day
																	? moment(membership.membership_day).format(
																			"DD/MM/YYYY"
																	  )
																	: "-"}
															</div>
															<div>
																<span className="font-bold text-[#00496B]">
																	F. Expiración:
																</span>{" "}
																{membership.expiry_date
																	? moment(membership.expiry_date).format(
																			"DD/MM/YYYY"
																	  )
																	: "-"}
															</div>
															{/* Aquí puedes agregar más información de cada membresía si es necesario */}
														</div>
													))}
													<div className="mt-3">
														<div>
															<span className="font-bold text-[#00496B]">
																Consultas disponibles
															</span>
														</div>
														{item.client.number_queries > 0 ? (
															<>
																{updateNumberQueries ? (
																	<form
																		onSubmit={(e) =>
																			onSubmit(e, item.client.id, item.client.nuip)
																		}
																		className="mt-3"
																	>
																		<input
																			type="text"
																			name="number_queries"
																			value={number_queries}
																			onChange={(e) => onChange(e)}
																			required
																			min={0}
																			max={1}
																			className="input input-sm input-bordered w-full max-w-xs"
																		/>
																		<div className="flex justify-between gap-4 mt-2">
																			<button
																				type="submit"
																				className="btn btn-sm border bg-white border-[#0096C4] text-[#0096C4] hover:bg-[#0096C4] hover:text-white"
																			>
																				Guardar
																			</button>
																			<button
																				type="button"
																				onClick={() =>
																					setUpdateNumberQueries(false)
																				}
																				className="btn btn-sm border bg-white border-[#0096C4] text-[#0096C4] hover:bg-[#0096C4] hover:text-white"
																			>
																				Cancelar
																			</button>
																		</div>
																	</form>
																) : (
																	<>
																		<div className="mt-2">
																			<div className="input input-sm bg-gray-100 rounded-lg w-full inline-block">
																				{item.client.number_queries}
																			</div>
																			<button
																				onClick={() => setUpdateNumberQueries(true)}
																				className="btn btn-sm border bg-white border-[#0096C4] text-[#0096C4] hover:bg-[#0096C4] hover:text-white mt-3 w-full"
																			>
																				Modificar
																			</button>
																		</div>
																	</>
																)}
															</>
														) : (
															<div className="px-4 py-3 bg-gray-100 rounded-lg w-full inline-block">
																{item.client.number_queries}
															</div>
														)}
													</div>
												</div>
											</div>
											<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg grid grid-cols-3 gap-x-4  place-content-center">
												<div className="font-bold text-center text-[#00496B] text-sm col-span-1">
													{item.client && item.client.client_vip === true ? (
														<>
															{item.client && item.client.plans  ? (() => {
																switch (item.client.plans) {
																	case "CLASSIC":
																		return (
																			<>
																				<div className="flex justify-center">
																					<img
																						src={planStandardVIP}
																						className="w-20"
																						alt="medal standard vip"
																					/>
																				</div>
																				<span className="">STANDARD </span>{" "}
																				<span className="">VIP</span>
																			</>
																		);
																	case "PLUS":
																		return (
																			<>
																				<div className="flex justify-center">
																					<img
																						src={planPlusVIP}
																						className="w-20"
																						alt="medal plus vip"
																					/>
																				</div>
																				<span className="">PLUS </span>{" "}
																				<span className="">VIP</span>
																			</>
																		);
																	case "PREMIUM":
																		return (
																			<>
																				<div className="flex justify-center">
																					<img
																						src={planPremiumVIP}
																						className="w-20"
																						alt="medal premium vip"
																					/>
																				</div>
																				<span className="">PREMIUM </span>{" "}
																				<span className="">VIP</span>
																			</>
																		);
																		case "PLATINUM":
																			return (
																				<>
																					<div className="flex justify-center">
																						<img
																							src={planPlatinumVIP}
																							className="w-20"
																							alt="medal platinum vip"
																						/>
																					</div>
																					<span className="">PLATINUM </span>{" "}
																					<span className="">VIP</span>
																				</>
																			);
																	default:
																		return <></>;
																} })() : "Sin Especificar"}
														</>
													) : (
														<>
															<div className="flex justify-center">
																{item.client && item.client.plans
																	? (() => {
																			switch (item.client.plans) {
																				case "CLASSIC":
																					return (
																						<>
																							<img
																								src={planStandard}
																								className="w-20"
																								alt="medal standard"
																							/>
																						</>
																					);
																				case "PLUS":
																					return (
																						<>
																							<img
																								src={planPlus}
																								className="w-20"
																								alt="medal plus"
																							/>
																						</>
																					);
																				case "PREMIUM":
																					return (
																						<>
																							<img
																								src={planPremium}
																								className="w-20"
																								alt="medal premium"
																							/>
																						</>
																					);
																				case "PLATINUM":
																					return (
																						<>
																							<img
																								src={planPlatinum}
																								className="w-20"
																								alt="medal platinum"
																							/>
																						</>
																					);
																				default:
																					return <></>;
																			}
																	  })()
																	: "Sin Especificar"}
															</div>
															<span className="">Plan </span>{" "}
															{item.client && item.client.plans
																? (() => {
																		switch (item.client.plans) {
																			case "CLASSIC":
																				return (
																					<span className="">Standard</span>
																				);
																			case "PLUS":
																				return <span className="">Plus</span>;
																			case "PREMIUM":
																				return (
																					<span className="">Premium</span>
																				);
																			case "PLATINUM":
																				return (
																					<span className="">Platinum</span>
																				);
																			default:
																				return (
																					<span className="">
																						Sin especificar
																					</span>
																				);
																		}
																  })()
																: "Sin Especificar"}
														</>
													)}
												</div>
												<div className="col-span-2">
													<span className="font-bold text-[#00496B]">Beneficios del plan</span>
													<div className="flex justify-center">
														{item.client && item.client.plans
															? (() => {
																	switch (item.client.plans) {
																		case "CLASSIC":
																			return (
																				<div>
																					<ul className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 list-disc pl-4">
																						<li className="py-0.5 text-xs">50 % descuento en consultas adicionales </li>
																						<li className="py-0.5 text-xs">⁠10 %  descuento en demás servicios de la Culm</li>
																					</ul>
																				</div>
																			);
																		case "PLUS":
																			return (
																				<div>
																					<ul className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 list-disc pl-4">
																						<li className="py-0.5 text-xs">70 % descuento en consultas</li>
																						<li className="py-0.5 text-xs">⁠Hasta 25%. Descuento en demás servicios Culm</li>
																					</ul>
																				</div>
																			);
																		case "PREMIUM":
																			return (
																				<div>
																					<ul className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 list-disc pl-4">
																						<li className="py-0.5 text-xs">70 % descuento en consultas</li>
																						<li className="py-0.5 text-xs">⁠Hasta 25%. Descuento en demás servicios Culm</li>
																					</ul>
																				</div>
																			);
																		case "PLATINUM":
																			return (
																				<div>
																					<ul className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 list-disc pl-4">
																						<li className="py-0.5 text-xs">70 % descuento en consultas</li>
																						<li className="py-0.5 text-xs">⁠Hasta 25%. Descuento en demás servicios Culm</li>
																					</ul>
																				</div>
																			);
																		default:
																			return <>1</>;
																	}
																})()
															: "Sin Especificar"}
													</div>
												</div>
											</div>
										</div>
										<div className="grid grid-cols-1 gap-4 mb-5 md:grid-cols-2">
											<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg">
												{item.client && item.client.client ? (
													<>
														{/* <div>
															<span className="font-bold text-[#00496B]">
																Titular
															</span>
															<ul>
																<li className="px-2 py-1">
																	{item.client.first_name}{" "}
																	{item.client.last_name}
																</li>
															</ul>
														</div> */}
													</>
												) : (
													<>
														<div>
															<span className="font-bold text-[#00496B]">
																Beneficiarios
															</span>
														</div>
														<div className="flex justify-center">
																{moment().diff(
																	moment(item.client.membership_day),
																	"months"
																) === 1 ||
																(item.client.beneficiaries &&
																	item.client.beneficiaries.length >= 3) ? (
																	<></>
																) : (
																	<BeneficiaryModal2 client={item.client.id}  onClose={handleModalClose}/>
																)}
															</div>
														<div>
															{item.client.beneficiaries &&
																item.client.beneficiaries.map((beneficiary) => (
																	<>
																		<ul className="mt-1 text-md text-gray-900 sm:mt-0 sm:col-span-2">
																			<li className="px-2 py-1">
																				{beneficiary.first_name}{" "}
																				{beneficiary.last_name}
																			</li>
																		</ul>
																		
																		<span className="font-bold text-[#00496B]">
																			Consultas disponibles
																		</span>

																		{beneficiary.number_queries > 0 ? (
																			<>
																				{updateNumberQueriesBeneficiaryId ===
																				beneficiary.id ? (
																					<form
																						onSubmit={(e) =>
																							onSubmitBeneficiary(
																								e,
																								beneficiary.id
																							)
																						}
																						className="mt-3"
																					>
																						<input
																							type="text"
																							name="number_queries"
																							value={formData.number_queries}
																							onChange={onChange}
																							required
																							min={0}
																							max={1}
																							className="input input-bordered max-w-xs"
																						/>
																						<div className="flex justify-start gap-4 mt-3">
																							<button
																								type="submit"
																								className="btn btn-sm border border-[#0096C4] text-[#0096C4] hover:bg-[#0096C4] hover:text-white"
																							>
																								Guardar
																							</button>
																							<button
																								type="button"
																								onClick={() =>
																									setUpdateNumberQueriesBeneficiaryId(
																										null
																									)
																								}
																								className="btn btn-sm border border-[#0096C4] text-[#0096C4] hover:bg-[#0096C4] hover:text-white"
																							>
																								Cancelar
																							</button>
																						</div>
																					</form>
																				) : (
																					<div className="flex flex-col">
																						<div className="px-4 py-3 bg-gray-100 rounded-lg w-44 inline-block">
																							{beneficiary.number_queries}
																						</div>
																						<button
																							onClick={() =>
																								setUpdateNumberQueriesBeneficiaryId(
																									beneficiary.id
																								)
																							}
																							className="btn btn-sm border border-[#0096C4] text-[#0096C4] hover:bg-[#0096C4] hover:text-white w-44 mt-3"
																						>
																							Modificar
																						</button>
																					</div>
																				)}
																			</>
																		) : (
																			<div className="px-4 py-3 bg-gray-100 rounded-lg w-full inline-block">
																				{beneficiary.number_queries}
																			</div>
																		)}
																	</>
																))}
														</div>
													</>
												)}
											</div>
											<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg">
												<div>
													<span className="font-bold text-[#00496B]">
														Bonos
													</span>
												</div>
												<div className="mt-2 text-sm text-gray-900">

													{
														item.client.bonus_culm > 0 && (
															<div>
																<span className="font-bold text-[#00496B]">
																	Culm:{" "}
																</span>
																{item.client.bonus_culm}
															</div>
														) 
													}
													{item.client.bonus_corpoindustrial > 0 && (
														<div>
															<span className="font-bold text-[#00496B]">
																Bonos Corpoindustrial:{" "}
															</span>
															{item.client.bonus_corpoindustrial}
														</div>
													)}
												</div>
											</div>
										</div>
										<div className="bg-white shadow-md shadow-[#bde3f3] p-4 mb-5 rounded-lg">
								<div className="flex flex-col items-center">
									<button
										onClick={() => {
											setShowDiscountFields(true);
										}}
										className="btn btn-sm border bg-white border-[#0096C4] text-[#0096C4] hover:bg-[#0096C4] hover:text-white"
									>
										Agregar Descuento
									</button>
									
								</div>
							</div>

							{/* Aquí puedes agregar la nueva sección para los campos del descuento */}
							{showDiscountFields && (
								<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg">
									<form onSubmit={(e) => onSubmitDiscount(e, item.client.id)}>
										<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
											<div>
												<span className="font-bold text-[#00496B]">Tipo</span>
											</div>
											<input
												type="text"
												name="type"
												value={type}
												onChange={(e) => onChange(e)}
												className="input input-bordered w-full" /* ... */
											/>

											<div>
												<span className="font-bold text-[#00496B]">Valor</span>
											</div>
											<input
												type="number"
												name="value"
												value={value}
												onChange={(e) => onChange(e)}
												className="input input-bordered w-full" /* ... */
											/>

											<div>
												<span className="font-bold text-[#00496B]">
													Descuento
												</span>
											</div>
											<input
												type="number"
												name="discount"
												value={discount}
												onChange={(e) => onChange(e)}
												className="input input-bordered w-full" /* ... */
											/>

											<div>
												<span className="font-bold text-[#00496B]">
													Numero de Factura
												</span>
											</div>
											<input
												type="text"
												name="invoice_code"
												value={invoice_code}
												onChange={(e) => onChange(e)}
												className="input input-bordered w-full" /* ... */
											/>
										</div>
										<div className="flex justify-end gap-4 mt-4">
											<button
												type="submit"
												className="btn btn-sm border border-[#0096C4] text-[#0096C4] hover:bg-[#0096C4] hover:text-white"
											>
												Enviar
											</button>
											<button
												onClick={() => {
													setShowDiscountFields(false);
												}}
												className="btn btn-sm border border-gray-300 text-gray-700 hover:bg-gray-300 hover:text-white"
											>
												Cerrar
											</button>
										</div>
									</form>
								</div>
							)}

							<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg">
								<span className="font-bold text-[#00496B]">
									Descuentos Consumidos
								</span>
								<div className="flex flex-col justify-center">
									{item.discounts && (
										<div className="overflow-x-auto">
											<table className="table table-xs">
												<thead>
													<tr>
														<th className="text-center">Tipo</th>
														<th className="text-center">Valor</th>
														<th className="text-center">Descuento</th>
														<th className="text-center">Número de Factura</th>
														<th className="text-center">Acciones</th>
													</tr>
												</thead>
												<tbody>
													{item.discounts.map((discount, index) => (
														<tr key={index}>
															<td className="text-center">{discount.type}</td>
															<td className="text-center">${discount.value}</td>
															<td className="text-center">
																${discount.discount}
															</td>
															<td className="text-center">
																{discount.invoice_code}
															</td>
															<td className="p-2 text-xs">
																<div className="flex gap-2 justify-center">
																	<div
																		className="flex tooltip"
																		data-tip="Editar"
																	>
																		<SearchModal
																			discounts={discount}
																			onClose={handleModalClose}
																		/>
																	</div>
																	<div
																		className="tooltip flex"
																		data-tip="Generar Reporte"
																	>
																		<a
																			className="p-1 bg-teal-400 text-white rounded-full"
																			href="#"
																		>
																			<IconFileDescription />
																		</a>
																	</div>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									)}
								</div>
							</div>
									</>
								) : item.client.active === false && item.client.status === false ? (
									<div className="bg-white shadow-md shadow-[#bde3f3] p-4 rounded-lg">
										<div className="flex justify-center">
											<p className="text-red-500">
												El usuario no se encuentra activo
											</p>
										</div>
									</div>
								) : (

											<div className="p-4">
												<p className="text-sm">
													Titular no tiene membresía y no esta verificado
												</p>
											</div>
								)}
						</div>
					))}
				</div>
			) : (
				<p className="m-auto flex justify-center mt-24">
					Para realizar una búsqueda ingresa el número de documento y darle
					click a la lupa o Enter
				</p>
			)}
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	search: state.search.search,
});

export default connect(mapStateToProps, { get_search })(Search);
